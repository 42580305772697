import * as React from "react";
import { observer } from 'mobx-react';

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";
import { QNLocalAudioTrack, QNRemoteAudioTrack } from "qnweb-rtc";

interface State {
  audioTrackID: string;
  volume: string;
  curVolume: number;
  interval?: NodeJS.Timeout;
}

interface Props {
  room: RoomStore;
}

@observer
export default class AudioVolumeControl extends React.Component<Props, State> {
  state: State = {
    audioTrackID: NONE,
    volume: "1",
    curVolume: 0
  };

  componentDidMount() {
    const interval = setInterval(() => {
      const t = this.props.room.audioTracks.find(track => track.trackID === this.state.audioTrackID);
      if (!t) {
        return;
      }
      if (t instanceof QNLocalAudioTrack || t instanceof QNRemoteAudioTrack) {
        const curVolume = t.getVolumeLevel();
        if (curVolume) {
          this.setState({ curVolume });
        }
      }
    }, 800);
    this.setState({ interval });
  }

  componentWillUnmount() {
    if (this.state.interval) clearInterval(this.state.interval);
  }

  handleSetVolume = () => {
    const t = this.props.room.audioTracks.find(track => track.trackID === this.state.audioTrackID);
    if (!t) {
      console.error("cannot find target audio track", this.state.audioTrackID);
      return;
    }
    if (t instanceof QNLocalAudioTrack || t instanceof QNRemoteAudioTrack) {
      t.setVolume(Number(this.state.volume));
    }
  };

  public render(): JSX.Element {
    return <div className="audio-volume-container">
      <h2>音量控制</h2>
      <label htmlFor="audio-control-audioTrackID">audioTrack: </label>
      <select id="audio-control-audioTrackID" value={this.state.audioTrackID} onChange={e => this.setState({ audioTrackID: e.target.value })}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.audioTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <br />
      <label htmlFor="volume-input">volume: </label>
      <input type="text" id="volume-input" value={this.state.volume} onChange={e => this.setState({ volume: e.target.value })} />
      <button onClick={() => this.handleSetVolume()}>setVolume</button>
      <br />
      <div>current volume: {this.state.curVolume}</div>
    </div>;
  }
}

