import * as React from "react";
import { observer } from 'mobx-react';

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";
import QNRTC from 'qnweb-rtc';

interface State {
  audioTrackID: string;
  audioActiveResult: string;
  videoTrackID: string;
  videoActiveResult: string;
}

interface Props {
  room: RoomStore;
}

@observer
export default class AudioBufferControl extends React.Component<Props, State> {
  state: State = {
    audioTrackID: NONE,
    videoTrackID: NONE,
    videoActiveResult: NONE,
    audioActiveResult:NONE,
  };

  async handleAudioActiveCheck() {
    this.setState({
      audioActiveResult: NONE
    });
    const curTrack:any = this.props.room.audioTracks.find(t => t.trackID === this.state.audioTrackID);
    if(curTrack) {
      const activeResult = await QNRTC.checkAudioTrackIsActive(curTrack);
      this.setState({
        audioActiveResult: activeResult.toString()
      });
    }
  };

  async handleVideoActiveCheck() {
    this.setState({
      videoActiveResult: NONE
    });
    const curTrack:any = this.props.room.videoTracks.find(t => t.trackID === this.state.videoTrackID);
    if(curTrack) {
      const activeResult = await QNRTC.checkVideoTrackIsActive(curTrack);
      this.setState({
        videoActiveResult: activeResult.toString()
      });
    }
  }

  handleChangeAudioTrack = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ audioTrackID: e.target.value });
  };

  handleChangeVideoTrack = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ videoTrackID: e.target.value });
  };

  public render(): JSX.Element {
    return <div className="audio-buffer-control-container">
      <h2>音频活动检测</h2>
      <label htmlFor="audio-buffer-control-audioTrackID">audioTrack: </label>
      <select id="audio-buffer-control-audioTrackID" value={this.state.audioTrackID} onChange={e => this.handleChangeAudioTrack(e)}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.audioTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <button onClick={this.handleAudioActiveCheck.bind(this)}>开始检测</button>
      { this.state.audioActiveResult && <div>检测结果: {this.state.audioActiveResult}</div> }
      <h2>视频活动检测</h2>
      <label htmlFor="audio-buffer-control-audioTrackID">videoTrack: </label>
      <select id="audio-buffer-control-audioTrackID" value={this.state.videoTrackID} onChange={e => this.handleChangeVideoTrack(e)}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.videoTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <button onClick={this.handleVideoActiveCheck.bind(this)}>开始检测</button>
      { this.state.videoActiveResult && <div>检测结果: {this.state.videoActiveResult}</div> }
    </div>;
  }
}

