import QNRTC, {QNCanvasVideoTrack, QNCanvasVideoTrackSource, QNVideoOptimizationMode} from "qnweb-rtc";
import * as React from "react";

interface Props {
  handlePub: (track: QNCanvasVideoTrack) => Promise<void>;
}

interface State {
  canvasWidth: string;
  canvasHeigth: string;
  canvasSources: QNCanvasVideoTrackSource[];
}

export default class CanvasTrack extends React.Component<Props, State> {
  canvasTrack: QNCanvasVideoTrack | null;

  constructor(props: Props) {
    super(props);

    this.canvasTrack = null;
  }

  state: State = {
    canvasWidth: "320",
    canvasHeigth: "240",
    canvasSources: [
      { source: "http://pili-playback.qnsdk.com/ivs_background_1280x720.png", x: 0, y: 0, width: 100, height: 100 }
    ]
  };

  handleChangeSource(key: string, value: number | string, index: number) {
    this.setState(old => {
      return {
        ...old,
        canvasSources: [
          ...old.canvasSources.slice(0, index),
          { ...old.canvasSources[index], [key]: value },
          ...old.canvasSources.slice(index + 1),
        ]
      };
    });
  }

  handleRemoveSource(index: number) {
    this.setState(old => {
      return {
        ...old,
        canvasSources: [
          ...old.canvasSources.slice(0, index),
          ...old.canvasSources.slice(index + 1),
        ]
      };
    });
  }

  render(): JSX.Element {
    return <div id="canvas-track-container">
      <h3>canvas track</h3>
      <label>canvas width:
        <input type="text" className="narrow-text-input" onChange={e => this.setState({ ...this.state, canvasWidth: e.target.value })} value={this.state.canvasWidth} />
      </label>
      <label>canvas height:
        <input type="text" className="narrow-text-input" onChange={e => this.setState({ ...this.state, canvasHeigth: e.target.value })} value={this.state.canvasHeigth} />
      </label>
      <h4>canvas sources:</h4>
      {
        this.state.canvasSources.map((source, index) => {
          return (
            <React.Fragment key={index}>
              <label> x:
                <input type="text" className="narrow-text-input" onChange={e => this.handleChangeSource("x", Number(e.target.value), index)} value={source.x} />
              </label>
              <label> y:
                <input type="text" className="narrow-text-input" onChange={e => this.handleChangeSource("y", Number(e.target.value), index)} value={source.y} />
              </label>
              <label> width:
                <input type="text" className="narrow-text-input" onChange={e => this.handleChangeSource("width", Number(e.target.value), index)} value={source.width} />
              </label>
              <label> height:
                <input type="text" className="narrow-text-input" onChange={e => this.handleChangeSource("height", Number(e.target.value), index)} value={source.height} />
              </label>
              <label> url:
                <input type="text" onChange={e => this.handleChangeSource("source", e.target.value, index)} value={source.source as string} />
              </label>
              <button onClick={() => this.handleRemoveSource(index)}>remove source</button>
              <br />
            </React.Fragment>
          );
        })
      }
      <button onClick={() => this.setState({ ...this.state, canvasSources: [...this.state.canvasSources, { source: "", x: 0, y: 0, width: 0, height: 0 }] })}>add source</button>
      <br />
      <button onClick={() => {
        if (this.canvasTrack) {
          this.canvasTrack.updateSources(this.state.canvasSources);
        }
      }}>update sources(发布之后调用)</button>
      <br />
      <button onClick={async () => {
        const track = QNRTC.createCanvasVideoTrack(
          {
            width: Number(this.state.canvasWidth),
            height: Number(this.state.canvasHeigth),
            sources: this.state.canvasSources,
            optimizationMode: QNVideoOptimizationMode.DETAIL,
            tag: "canvas-track"
          }
        );
        this.canvasTrack = track;
        this.props.handlePub(track);
      }}>发布 canvas track</button>

    </div>;
  }

}
