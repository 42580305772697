import * as React from "react";
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores/roomStore";
import QNRTC, { QNAudioSourceState, QNMicrophoneAudioTrack, QNAudioMusicMixer, QNAudioFilter, QNAudioMixingTrack, QNLocalAudioTrack } from "qnweb-rtc";
import MusicBar from "../MusicBar";
import { NONE } from "../../constants";

interface Props {
  room: RoomStore;
}

interface State {
  /**
   * 背景音乐文件
   */
  musicFile?: File;
  /**
   * 背景音乐URL
   */
  musicURL: string;
  /**
   * 选择的麦克风 trackID
   */
  selectedMicrophoneTrackID: string;
  /**
   * 外部音频处理状态
   */
  sourceState: QNAudioSourceState | "";
  /**
   * 麦克风音量
   */
  musicVolume: string;
  /**
   * 扬声器音量
   */
  microphoneVolume: string;
  /**
   * 循环次数
   */
  loopCount: number;
  /**
   * 音乐混音
   */
  audioMusicMixer?: QNAudioMusicMixer;
  /**
   * 压缩过滤器
   */
  compressorMixer: CompressorFilter;
  /**
   * 正在音乐过滤的音频轨
   */
  musicFilterTrackList : QNLocalAudioTrack[];
  /**
   * 压缩过滤的音频轨
   */
  compressFilterTrackList: QNLocalAudioTrack[];
}

class CompressorFilter implements QNAudioFilter {
  private compressor: any;
  constructor() {
    //@ts-ignore
    this.compressor = audioContext.createDynamicsCompressor();
    //@ts-ignore
    this.compressor.threshold.setValueAtTime(-50, audioContext.currentTime);
    //@ts-ignore
    this.compressor.knee.setValueAtTime(40, audioContext.currentTime);
    //@ts-ignore
    this.compressor.ratio.setValueAtTime(12, audioContext.currentTime);
    //@ts-ignore
    this.compressor.attack.setValueAtTime(0, audioContext.currentTime);
    //@ts-ignore
    this.compressor.release.setValueAtTime(0.25, audioContext.currentTime);
  }
  add(track: QNAudioMixingTrack) {
    this.compressor.connect(track.destination);
    track.inputList.forEach((audioTrack: any) => {
      audioTrack.track.audioManager.gainNode.disconnect(track.destination);
      audioTrack.track.audioManager.gainNode.connect(this.compressor);
    })
  }

  remove(track: QNAudioMixingTrack) {
    this.compressor.disconnect(track.destination);
    track.inputList.forEach((audioTrack: any) => {
      audioTrack.track.audioManager.gainNode.disconnect(this.compressor);
      audioTrack.track.audioManager.gainNode.connect(track.destination);
    })
  }
}

@observer
export default class AudioMixingControl extends React.Component<Props, State> {
  state: State = {
    musicURL: "https://docs.qnsdk.com/eva_edm.mp3",
    selectedMicrophoneTrackID: NONE,
    sourceState: "",
    musicVolume: "1",
    microphoneVolume: "1",
    loopCount: 1,
    audioMusicMixer: undefined,
    musicFilterTrackList: [],
    compressFilterTrackList: [],
    compressorMixer: new CompressorFilter()
  };

  private handleChangeMusicFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file) return;
    this.state.musicFile = file;
  };

  updateSourceState(state: QNAudioSourceState) {
     this.setState({ sourceState: state });
  }

  showMusicMixerError(error: any) {
      alert(`错误信息：${error.code} ${error.message}`);
  }

  componentWillUnmount() {
    this.state.audioMusicMixer && this.state.audioMusicMixer.off("state-changed", this.updateSourceState);
    this.state.audioMusicMixer && this.state.audioMusicMixer.off("error", this.showMusicMixerError);
    this.state.audioMusicMixer && this.state.audioMusicMixer.release();
  }

  /**
   * 创建音乐混音管理器
   */
  private createAudioMusicMixer() {
    this.setState({
      audioMusicMixer: this.state.musicFile ? QNRTC.createAudioMusicMixer(
        this.state.musicFile
      ) : QNRTC.createAudioMusicMixer(
        this.state.musicURL
      )
    })
    if (this.state.audioMusicMixer) {
      this.state.audioMusicMixer.on("state-changed", this.updateSourceState);
      this.state.audioMusicMixer.on("error", this.showMusicMixerError);
    }
  }

  /**
   * 移除音乐混音管理器
   */
  private destroyAudioMusicMixer() {
    QNRTC.destroyAudioMusicMixer();
    this.setState({
      audioMusicMixer: undefined
    })
  }

  /**
   * 添加音乐混音过滤器
   */
  addMusicAudioFilter() {
    const audioTrack = this.chooseSelectAudioTrack();
    if (audioTrack) {
      const chooseTrack = this.state.musicFilterTrackList.find(track => track === audioTrack);
      if (!chooseTrack) {
        this.setState({
          musicFilterTrackList: [...this.state.musicFilterTrackList, audioTrack]
        })
        this.state.audioMusicMixer && audioTrack.addAudioFilter(this.state.audioMusicMixer);
      } 
    }
  }

  /**
   * 移除音乐混音过滤器
   */
  removeMusicAudioFilter() {
    const audioTrack = this.chooseSelectAudioTrack();
    if (audioTrack) {
      const chooseTrackIndex = this.state.musicFilterTrackList.findIndex(track => track === audioTrack);
      if (chooseTrackIndex >= 0) {
        const musicFilterTrackList = [...this.state.musicFilterTrackList];
        musicFilterTrackList.splice(chooseTrackIndex, 1);
        this.setState({
          musicFilterTrackList
        })
        this.state.audioMusicMixer && audioTrack.removeAudioFilter(this.state.audioMusicMixer);
      }
    }
  }

  addCompressAudioFilter() {
    const audioTrack = this.chooseSelectAudioTrack();
    if (audioTrack) {
      const chooseTrack = this.state.compressFilterTrackList.find(track => track === audioTrack);
      if (!chooseTrack) {
        this.setState({
          compressFilterTrackList: [...this.state.compressFilterTrackList, audioTrack]
        })
        audioTrack.addAudioFilter(this.state.compressorMixer);
      } 
    }
  }

  removeCompressAudioFilter() {
    const audioTrack = this.chooseSelectAudioTrack();
    if (audioTrack) {
      const chooseTrackIndex = this.state.compressFilterTrackList.findIndex(track => track === audioTrack);
      if (chooseTrackIndex >= 0) {
        const compressFilterTrackList = [...this.state.compressFilterTrackList];
        compressFilterTrackList.splice(chooseTrackIndex, 1);
        this.setState({
          compressFilterTrackList
        })
        audioTrack.removeAudioFilter(this.state.compressorMixer);
      }
    }
  }

  /**
   * 选择选中的音频轨
   * @returns 本地音频轨
   */
  private chooseSelectAudioTrack(): QNLocalAudioTrack | undefined {
    const microphoneTrack = this.props.room.publishedTracks.filter(
      (t) => t.trackID === this.state.selectedMicrophoneTrackID
    )[0];
    if (!microphoneTrack) {
      console.error(
        "no selected microphone track, selectedMicrophoneTrackID: ",
        this.state.selectedMicrophoneTrackID
      );
      return;
    }
    return microphoneTrack as QNLocalAudioTrack
  }

  /**
   * 开启耳返
   * @param microphoneTrackID
   */
  setEarMonitorEnabled(microphoneTrackID: string) {
    const microphoneAudioTrack = this.handleGetMicrophoneAudioTrackById(microphoneTrackID);
    if (microphoneAudioTrack.isEarMonitorEnabled()) {
      microphoneAudioTrack.setEarMonitorEnabled(false);
    } else {
      microphoneAudioTrack.setEarMonitorEnabled(true);
    }
  }

  /**
   * 设置音乐音量
   * @param microphoneTrackID 
   * @returns 
   */
  setMixingMusicVolume() {
    this.state.audioMusicMixer && this.state.audioMusicMixer.setMixingVolume(
      Number(this.state.musicVolume)
    );
  }

  /**
   * 设置麦克风音量
   * @param microphoneTrackID 
   */
  setMixingMicrophoneVolume(microphoneTrackID: string) {
    const microphoneAudioTrack = this.handleGetMicrophoneAudioTrackById(microphoneTrackID);
    microphoneAudioTrack.setVolume(Number(this.state.microphoneVolume))
  }

  /**
   * 根据 ID 获取音频 Track
   * @param microphoneTrackID ID
   * @returns 音频 Track
   */
  private handleGetMicrophoneAudioTrackById(microphoneTrackID: string): QNMicrophoneAudioTrack {
    const microphoneTrack = this.props.room.publishedTracks.filter(
      (t) => t.trackID === microphoneTrackID
    )[0];
    if (!microphoneTrack) {
      throw new Error(
        `no selected microphone track, selectedMicrophoneTrackID: ${
            microphoneTrackID
        }`
      );
    } else {
      return microphoneTrack as QNMicrophoneAudioTrack;
    }
  }

  public render(): JSX.Element {
    return (
      <div className="audio-mixing-control-container ">
        <h2>混音背景控制-新版</h2>
        <div className="create-audio-mixer-container">
          <label htmlFor="audio-mixer-source-input">选择音乐文件： </label>
          <input
            id="audio-mixer-source-input"
            type="file"
            accept=".mp3, .ogg"
            onChange={this.handleChangeMusicFile}
          />
          <br />
          <label htmlFor="audio-mixer-source-url">输入音乐地址：</label>
          <input
            className="online-audio"
            type="text"
            value={this.state.musicURL}
            onChange={(e) => this.setState({ musicURL: e.target.value })}
          />
          <br />
          <label htmlFor="audio-mixer-source-input">循环次数： </label>
          <input
            id="audio-mixer-source-input"
            type="number"
            value={this.state.loopCount}
            onChange={(e) => this.setState({ loopCount: Number(e.target.value) })}
          />
          <br />
          { !this.state.audioMusicMixer && <button
            id="api-createAudioMixer"
            onClick={() => this.createAudioMusicMixer()}
          >
            创建混音音乐控制器
          </button> }
          { this.state.audioMusicMixer && <button
            id="api-createAudioMixer"
            onClick={() => this.destroyAudioMusicMixer()}
          >
            移除混音音乐控制器
          </button> }
          <br />
          <label htmlFor="audio-mixer-microphone-track">
            选择麦克风 Track:{" "}
          </label>
          <select
            id="audio-mixer-microphone-track"
            value={this.state.selectedMicrophoneTrackID}
            onChange={(e) =>
              this.setState({ selectedMicrophoneTrackID: e.target.value })
            }
          >
            <option value={NONE}>{NONE}</option>
            {this.props.room.localAudioTracks.map((t) => (
              <option key={t.trackID} value={t.trackID}>
                {t.trackID}
              </option>
            ))}
          </select>
          <br />
          <button
            id="api-createAudioMixer"
            onClick={() => this.addMusicAudioFilter()}
          >
            添加混音过滤器
          </button>
          <br />
          <button
            id="api-createAudioMixer"
            onClick={() => this.removeMusicAudioFilter()}
          >
            移除混音过滤器
          </button>
          <br />
          <button
            id="api-createAudioMixer"
            onClick={() => this.addCompressAudioFilter()}
          >
            添加音频压缩过滤器
          </button>
          <br />
          <button
            id="api-createAudioMixer"
            onClick={() => this.removeCompressAudioFilter()}
          >
            移除音频压缩过滤器
          </button>
        </div>
        <div>
          {this.state.musicFilterTrackList.map(
            (track) => {
              return <div className="audio-mixer-item" key={track.trackID}>
                    <h4>
                      microphoneTrack:{track.trackID}
                    </h4>
                    <button
                      className="audio-mixer-control-playback"
                      onClick={() => this.setEarMonitorEnabled(track.trackID!)}
                    >
                      开启/关闭耳返
                    </button>
                    <br />
                    <label htmlFor="audio-mixer-playing-volume-microphone">
                      背景音乐播放音量:
                    </label>
                    <input
                      type="text"
                      id="audio-mixer-playing-volume-microphone"
                      value={this.state.musicVolume}
                      onChange={(e) =>
                        this.setState({ musicVolume: e.target.value })
                      }
                    />
                    <button
                      onClick={() =>
                        this.setMixingMusicVolume()
                      }
                    >
                      设置背景音乐播放音量
                    </button>
                    <br />
                    <label htmlFor="audio-mixer-playing-volume-music">
                      麦克风音量
                    </label>
                    <input
                      type="text"
                      id="audio-mixer-playing-volume-music"
                      value={this.state.microphoneVolume}
                      onChange={(e) =>
                        this.setState({ microphoneVolume: e.target.value })
                      }
                    />
                    <button
                      onClick={() =>
                        this.setMixingMicrophoneVolume(track.trackID!)
                      }
                    >
                      设置混音扬声器音量
                    </button>
                    <br />
                    <button
                      className="audio-mixer-control-start"
                      onClick={() => this.state.audioMusicMixer && this.state.audioMusicMixer.start(this.state.loopCount || 1)}
                    >
                      start
                    </button>
                    <button
                      className="audio-mixer-control-stop"
                      onClick={() => this.state.audioMusicMixer && this.state.audioMusicMixer.stop()}
                    >
                      stop
                    </button>
                    <button
                      className="audio-mixer-control-pause"
                      onClick={() => this.state.audioMusicMixer && this.state.audioMusicMixer.pause()}
                    >
                      pause
                    </button>
                    <button
                      className="audio-mixer-control-resume"
                      onClick={() => this.state.audioMusicMixer && this.state.audioMusicMixer.resume()}
                    >
                      resume
                    </button>
                    <br />
                    <span>{this.state.sourceState}</span>
                    <MusicBar controller={this.state.audioMusicMixer} />
                  </div>
            }
          )}
        </div>
        <br />
      </div>
    );
  }
}