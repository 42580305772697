import * as React from "react";
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores/roomStore";
import { QNAudioSourceState, QNBufferSourceAudioTrack } from 'qnweb-rtc';
import { NONE } from "../../constants";
import MusicBar from "../MusicBar";

interface Props {
  room: RoomStore;
}

interface State {
  selectedBufferSourceAudioTrackID: string,
  bufferSourceAudioTrack?: QNBufferSourceAudioTrack;
  sourceState: QNAudioSourceState | "";
}

@observer
export default class BufferSourceAudioControl extends React.Component<Props, State> {
  state: State = {
    selectedBufferSourceAudioTrackID: NONE,
    sourceState: ""
  };

  private handleBufferSourceAudioControl(type: string): void {
    if (!this.state.bufferSourceAudioTrack) {
      console.error("can not find audio source track!");
      return;
    }
    switch (type) {
      case "start": {
        this.state.bufferSourceAudioTrack.start();
        break;
      }
      case "stop": {
        this.state.bufferSourceAudioTrack.stop();
        break;
      }
      case "pause": {
        this.state.bufferSourceAudioTrack.pause();
        break;
      }
      case "resume": {
        this.state.bufferSourceAudioTrack.resume();
        break;
      }
      default: {
        console.error("Invalid buffer source audio ");
      }
    }
  }

  updateSourceState = (state: QNAudioSourceState) => {
    this.setState({ sourceState: state });
  };

  componentWillUnmount() {
    if (this.state.bufferSourceAudioTrack) {
      this.state.bufferSourceAudioTrack.off("state-changed", this.updateSourceState);
    }
  }

  public render(): JSX.Element {
    return <div className="buffersourceaudio-control-container ">
      <h2>外部音频导入</h2>
      <label htmlFor="buffer-source-audio-track-select">选择要操作的 Buffer Source Track：</label>
      <select
        id="buffer-source-audio-track-select"
        value={this.state.selectedBufferSourceAudioTrackID}
        onChange={e => {
          this.setState({ selectedBufferSourceAudioTrackID: e.target.value });
          if (this.state.bufferSourceAudioTrack) {
            this.state.bufferSourceAudioTrack.off("state-changed", this.updateSourceState);
          }
          const track = this.props.room.bufferSourceAudioTracks.filter(t => t.trackID === e.target.value)[0];
          if (track) {
            this.setState({ bufferSourceAudioTrack: track });
            track.on("state-changed", this.updateSourceState);
          }
        }}>
        <option value={NONE}>{NONE}</option>
        {
          this.props.room.bufferSourceAudioTracks.map(t => <option key={t.trackID} value={t.trackID}>{t.trackID}</option>)
        }
      </select>
      <div className="buffer-source-audio-control">
        <button className="buffer-source-audio-control-start" onClick={() => this.handleBufferSourceAudioControl("start")}>start</button>
        <button className="buffer-source-audio-control-stop" onClick={() => this.handleBufferSourceAudioControl("stop")}>stop</button>
        <button className="buffer-source-audio-control-pause" onClick={() => this.handleBufferSourceAudioControl("pause")}>pause</button>
        <button className="buffer-source-audio-control-resume" onClick={() => this.handleBufferSourceAudioControl("resume")}>resume</button>
        <span>{this.state.sourceState}</span>
        <MusicBar controller={this.state.bufferSourceAudioTrack} />
      </div>
    </div>;
  }
}