import QNRTC, { QNCameraVideoTrack, QNScreenVideoTrack } from "qnweb-rtc";
import * as React from "react";

interface Props {
  handlePub: (track: QNCameraVideoTrack | QNScreenVideoTrack) => Promise<void>;
}

interface State {
  scaleResolutionDownBy: string;
  bitrate: string;
  lowStreamBitrate: string;
}

export default class LowStream extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state: State = {
    scaleResolutionDownBy: "2",
    bitrate: "3000",
    lowStreamBitrate: "100",
  };

  handlePubCameraOld = async () => {
    const track = await QNRTC.createCameraVideoTrack({
      encoderConfig: {
        isMultiProfileEnabled: true,
        bitrate: Number(this.state.bitrate)
      }
    });
    this.props.handlePub(track);
  };

  handlePubScreenOld = async () => {
    const track = await QNRTC.createScreenVideoTrack({
      encoderConfig: {
        isMultiProfileEnabled: true,
        bitrate: Number(this.state.bitrate)
      }
    }, "disable");
    if (Array.isArray(track)) {
      console.error("disable audio but got array response in QNRTC.createScreenVideoTrack");
    } else {
      this.props.handlePub(track);
    }
  };

  handlePubCameraNew = async () => {
    const track = await QNRTC.createCameraVideoTrack({
      encoderConfig: {
        lowStreamConfig: {
          bitrate: Number(this.state.lowStreamBitrate),
          scaleResolutionDownBy: Number(this.state.scaleResolutionDownBy)
        },
        bitrate: Number(this.state.bitrate)
      }
    });
    this.props.handlePub(track);
  };

  handlePubScreenNew = async () => {
    const track = await QNRTC.createScreenVideoTrack({
      encoderConfig: {
        lowStreamConfig: {
          bitrate: Number(this.state.lowStreamBitrate),
          scaleResolutionDownBy: Number(this.state.scaleResolutionDownBy)
        },
        bitrate: Number(this.state.bitrate)
      }
    }, "disable");
    if (Array.isArray(track)) {
      console.error("disable audio but got array response in QNRTC.createScreenVideoTrack");
    } else {
      this.props.handlePub(track);
    }
  };


  render(): JSX.Element {
    return <div id="low-stream-container">
      <h3>大小流配置</h3>
      <button onClick={this.handlePubCameraOld}>发布摄像头大小流（旧版）</button>
      <button onClick={this.handlePubScreenOld}>发布屏幕分享大小流（旧版）</button>
      <label>
        scaleResolutionDownBy:
        <input type="text" value={this.state.scaleResolutionDownBy} onChange={e => this.setState({ scaleResolutionDownBy: e.target.value })} className="narrow-input" />
      </label>
      <label>
        bitrate:
        <input type="text" value={this.state.bitrate} onChange={e => this.setState({ bitrate: e.target.value })} className="narrow-input" />
      </label>
      <label>
        bitrate(lowStream):
        <input type="text" value={this.state.lowStreamBitrate} onChange={e => this.setState({ lowStreamBitrate: e.target.value })} className="narrow-input" />
      </label>
      <br />
      <button onClick={this.handlePubCameraNew}>发布摄像头大小流（新版）</button>
      <button onClick={this.handlePubScreenNew}>发布屏幕分享大小流（新版）</button>
    </div>;
  }
}
