import { observable, action, computed } from 'mobx';
import { RTC_APP_ID } from '../constants/http';
import { RouterStore } from './routerStore';

import QNRTC, {
  QNRTCClient, QNConnectionState, QNLocalTrack, QNRemoteTrack,
  QNRemoteUser, QNMicrophoneAudioTrack, QNBufferSourceAudioTrack, QNTrack,
  QNConnectionDisconnectedInfo, QNConnectionDisconnectedReason, QNLocalVideoTrack, QNRemoteVideoTrack, QNClientMode, QNClientRole, QNLogLevel, QNLogConfig, QNLocalAudioTrack
} from 'qnweb-rtc';
import { assertUserPublished } from '../utils/asserts';

export class RoomStore {

  @observable
  public roomToken: string = "";

  @observable
  public appID: string = RTC_APP_ID;

  @observable
  public roomName: string = "";

  @observable
  public userID: string = "";

  @observable
  public publishedTracks: QNLocalTrack[] = []; // 已发布 local track 数组

  @observable
  public subscribedTracks: QNRemoteTrack[] = []; // 已订阅 remote track 数组

  @observable
  public notYetSubscribedTracks: QNRemoteTrack[] = []; // 尚未订阅的 remote track 数组

  @observable
  public connectionState: QNConnectionState = QNConnectionState.DISCONNECTED;

  @observable
  public remoteUsers: QNRemoteUser[] = [];

  @observable
  cameras: MediaDeviceInfo[] = [];

  @observable
  microphones: MediaDeviceInfo[] = [];

  @observable
  playbackDevices: MediaDeviceInfo[] = [];

  @observable
  trackShots: string[] = [];

  public rtcClient: QNRTCClient = QNRTC.createClient();

  private router: RouterStore;

  public constructor(router: RouterStore) {
    this.router = router;

    QNRTC.onCameraChanged = deviceInfo => {
      console.log("camera changed: ", deviceInfo.state, deviceInfo.device);
      QNRTC.getCameras(true)
        .then(cameras => {
          this.setCameras(cameras);
        })
        .catch(error => {
          console.error(error);
        });
    };

    QNRTC.onMicrophoneChanged = deviceInfo => {
      console.log("microphone changed: ", deviceInfo.state, deviceInfo.device);
      QNRTC.getMicrophones(true)
        .then(microphones => {
          this.setMicrophones(microphones);
        })
        .catch(error => {
          console.error(error);
        });
    };

    QNRTC.onPlaybackDeviceChanged = deviceInfo => {
      console.log("playback changed: ", deviceInfo.state, deviceInfo.device);
      QNRTC.getPlaybackDevices(true)
        .then(playbacks => {
          this.setPlaybackDevices(playbacks);
        })
        .catch(error => {
          console.error(error);
        });
    };
    this.rtcClient.on("connection-state-changed", (connectionState: QNConnectionState, info?: QNConnectionDisconnectedInfo) => {
      this.updateConnectionState(connectionState);
      if (connectionState === QNConnectionState.DISCONNECTED) {
        if (info!.reason === QNConnectionDisconnectedReason.ERROR) {
          alert(`Disconnected, errorCode: ${info!.errorCode}, errorMessage: ${info!.errorMessage}`);
          console.log("localTracks:", (this.rtcClient as any)._localTracks);
          this.release();
        }
        if (info!.reason === QNConnectionDisconnectedReason.KICKED_OUT) {
          alert(`Be kicked out`);
          this.release();
        }
      }
    });
    this.rtcClient.on("user-joined", (userID: string) => {
      const user = this.rtcClient.getRemoteUser(userID);
      if (!user) {
        console.error("cannot find remote user: ", userID);
        return;
      }
      this.syncRemoteUsers([...this.remoteUsers, user]);
    });

    this.rtcClient.on("user-left", (userID: string) => {
      const users = this.remoteUsers.filter(remoteUser => remoteUser.userID !== userID);
      if (users.length === this.remoteUsers.length) {
        console.error("cannot find remote user: ", userID);
        return;
      }
      this.syncRemoteUsers(users);
    });

    this.rtcClient.on("user-published", (userID: string, tracks: QNRemoteTrack[]) => {
      this.syncNotYetSubscribedTracks([...this.notYetSubscribedTracks, ...tracks]);
      assertUserPublished(userID, tracks, this.rtcClient);
    });

    this.rtcClient.on("user-unpublished", (_: string, tracks: QNRemoteTrack[]) => {
      this.syncNotYetSubscribedTracks(this.notYetSubscribedTracks.filter(remoteTrack => !tracks.includes(remoteTrack)));
      this.syncSubscribedTracks(this.subscribedTracks.filter(remoteTrack => !tracks.includes(remoteTrack)));
    });

    window.onbeforeunload = () => this.leave();
    (window as any).room = this;
  }

  @action
  public setRoomInfo(info: { appID?: string, userID?: string, roomName?: string, roomToken?: string; }): void {
    if (info.appID) this.appID = info.appID;
    if (info.userID) this.userID = info.userID;
    if (info.roomName) this.roomName = info.roomName;
    if (info.roomToken) this.roomToken = info.roomToken;
  }

  @action
  public uploadLog(): void {
    QNRTC.uploadLog();
  }

  @action
  public setLogConfig(config: QNLogConfig): void {
    QNRTC.setLogConfig(config);
  }

  @action
  public updateConnectionState(state: QNConnectionState): void {
    this.connectionState = state;
    (window as any).__test__roomState = state;
  }

  @action
  setCameras(cameras: MediaDeviceInfo[]) {
    this.cameras = cameras;
  }

  @action
  setMicrophones(microphones: MediaDeviceInfo[]) {
    this.microphones = microphones;
  }

  @action
  setPlaybackDevices(playbackDevices: MediaDeviceInfo[]) {
    this.playbackDevices = playbackDevices;
  }

  @action
  syncPublishedTracks(tracks: QNLocalTrack[]) {
    this.publishedTracks = tracks;
  }

  // 已发布的麦克风 track
  @computed get microphoneTracks(): QNMicrophoneAudioTrack[] {
    let mts: QNMicrophoneAudioTrack[] = [];
    for (let t of this.publishedTracks) {
      if (t.tag === "microphone") {
        mts.push(t as QNMicrophoneAudioTrack);
      }
    }
    return mts;
  }

  // 已发布的麦克风 track
  @computed get localAudioTracks(): QNLocalAudioTrack[] {
    let mts: QNLocalAudioTrack[] = [];
    for (let t of this.publishedTracks) {
      if (t instanceof QNLocalAudioTrack) {
        mts.push(t as QNLocalAudioTrack);
      }
    }
    return mts;
  }

  // 已发布的 buffer source audio track
  @computed get bufferSourceAudioTracks(): QNBufferSourceAudioTrack[] {
    let bts: QNBufferSourceAudioTrack[] = [];
    for (let t of this.publishedTracks) {
      if (t.tag === "bufferSourceAudio") {
        bts.push(t as QNBufferSourceAudioTrack);
      }
    }
    return bts;
  }

  // 房间内所有的 audio track（已发布 + 已订阅 + 未订阅）
  @computed get audioTracks(): QNTrack[] {
    return [...this.publishedTracks, ...this.subscribedTracks, ...this.notYetSubscribedTracks].filter(t => t.isAudio());
  }

  // 房间内所有的 video track （已发布 + 已订阅 + 未订阅）
  @computed get videoTracks(): QNTrack[] {
    return [...this.publishedTracks, ...this.subscribedTracks, ...this.notYetSubscribedTracks].filter(t => t.isVideo());
  }

  // 房间内所有的 track
  @computed get tracks(): QNTrack[] {
    return [...this.publishedTracks, ...this.subscribedTracks, ...this.notYetSubscribedTracks];
  }

  @action
  syncNotYetSubscribedTracks(tracks: QNRemoteTrack[]) {
    this.notYetSubscribedTracks = tracks;
  }

  @action
  syncSubscribedTracks(tracks: QNRemoteTrack[]) {
    this.subscribedTracks = tracks;
  }

  @action
  syncRemoteUsers(users: QNRemoteUser[]) {
    this.remoteUsers = users;
  }

  @action
  takeTrackShot(track: QNLocalVideoTrack | QNRemoteVideoTrack) {
    this.trackShots.push(track.getCurrentFrameData());
  }

  public leave() {
    this.rtcClient.leave();
    this.release();
  }

  public release() {
    this.publishedTracks.forEach(t => t.destroy());
    this.syncPublishedTracks([]);
    this.syncNotYetSubscribedTracks([]);
    this.syncSubscribedTracks([]);
    this.syncRemoteUsers([]);
    this.setRoomInfo({ appID: "", userID: "", roomName: "", roomToken: "" });
    this.router.push("/");
  }
}
