import * as React from "react";
import { observer } from 'mobx-react';
import QNRTC from "qnweb-rtc";

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";
import { QNLiveStreamingState, QNDeviceState } from "qnweb-rtc";


const deviceManger = QNRTC.createDeviceManger();

interface State {
  testMicVolume: number;
  isStartRecordingDeviceTest: boolean;
  recordingDevices: MediaDeviceInfo[];
  selectRecordingDeviceID: string;
  playbackDevices: MediaDeviceInfo[];
  selectPlaybackDeviceID: string;
  cameraDevices: MediaDeviceInfo[];
  selectCameraDeviceID: string;
  recordingDeviceInterval: number;
  recordingDevicePlayback: boolean
}

interface Props {}

@observer
export default class DirectLiveStreaming extends React.Component<Props, State> {
  state: State = {
    testMicVolume: 0,
    isStartRecordingDeviceTest: false,
    recordingDevices: [],
    selectRecordingDeviceID: "",
    playbackDevices: [],
    selectPlaybackDeviceID: "",
    cameraDevices: [],
    selectCameraDeviceID: "",
    recordingDeviceInterval: 1000,
    recordingDevicePlayback: false
  };

  componentDidMount() {
    deviceManger.removeAllListeners();
    console.log("监听 device-changed 事件");
    deviceManger.on("test-recording-device-volume", (volume: number) => {
      this.setState({
        testMicVolume: volume
      });
    })
    // deviceManger.on("recording-device-changed", (device: MediaDeviceInfo, state: QNDeviceState) => {
    //   alert(`事件名：recording-device-changed，设备名：${device.label}，状态：${state}`);
    // })
    // deviceManger.on("playback-device-changed", (device: MediaDeviceInfo, state: QNDeviceState) => {
    //   alert(`事件名：playback-device-changed，设备名：${device.label}，状态：${state}`);
    // })
    // deviceManger.on("camera-device-changed", (device: MediaDeviceInfo, state: QNDeviceState) => {
    //   alert(`事件名：camera-device-changed，设备名：${device.label}，状态：${state}`);
    // })
  }
  

  startRecordingDeviceTest = () => {
    deviceManger.startRecordingDeviceTest(this.state.recordingDeviceInterval, true);
    this.setState({
      isStartRecordingDeviceTest: true
    });
  }

  stopRecordingDeviceTest = () => {
    deviceManger.stopRecordingDeviceTest();
    this.setState({
      isStartRecordingDeviceTest: false
    });
  }

  stopPlaybackDeviceTest = () => {
    deviceManger.stopPlaybackDeviceTest();
  }

  startPlaybackDeviceTest = () => {
    deviceManger.startPlaybackDeviceTest("https://docs.qnsdk.com/eva_edm.mp3");
  }

  getRecordingDevices = async () => {
    const recordingDevices = await deviceManger.getRecordingDevices();
    this.setState({
      recordingDevices,
      selectRecordingDeviceID: recordingDevices.length ? recordingDevices[0].deviceId : ""
    });
  }

  getPlaybackDevices = async () => {
    const playbackDevices = await deviceManger.getPlaybackDevices();
    this.setState({
      playbackDevices,
      selectPlaybackDeviceID: playbackDevices.length ? playbackDevices[0].deviceId : ""
    });
  }

  getCameraDevices = async () => {
    const cameraDevices = await deviceManger.getCameraDevices();
    this.setState({
      cameraDevices,
      selectCameraDeviceID: cameraDevices.length ? cameraDevices[0].deviceId : ""
    });
  }
  
  setRecordingDevice = async () => {
    deviceManger.setRecordingDevice(this.state.selectRecordingDeviceID);
  }

  setPlaybackDevice = async () => {
    deviceManger.setPlaybackDevice(this.state.selectPlaybackDeviceID);
  }

  setCamerDevice = async () => {
    deviceManger.setCameraDevice(this.state.selectCameraDeviceID);
  }

  public render(): JSX.Element {
    return <div className="direct-livestreaming-container">
      <h2>设备检测</h2>
      <button onClick={this.getRecordingDevices}>获取麦克风设备</button>
      <select name="recordingDevices" value={this.state.selectRecordingDeviceID} onChange={(e) => this.setState({selectRecordingDeviceID: e.target.value})}>
        {this.state.recordingDevices.map(device => (
          <option value={device.deviceId} key={device.deviceId}>{device.label}</option>
        ))}
      </select>
      <button onClick={this.setRecordingDevice}>设置麦克风设备</button><span>{this.state.selectRecordingDeviceID}</span>
      <br />
      <button onClick={this.getPlaybackDevices}>获取扬声器设备</button>
      <select name="playbackDevices" value={this.state.selectPlaybackDeviceID} onChange={(e) => this.setState({selectPlaybackDeviceID: e.target.value})}>
        {this.state.playbackDevices.map(device => (
          <option value={device.deviceId} key={device.deviceId}>{device.label}</option>
        ))}
      </select>
      <button onClick={this.setPlaybackDevice}>设置扬声器设备</button><span>{this.state.selectPlaybackDeviceID}</span>
      <br />
      <button onClick={this.getCameraDevices}>获取摄像头设备</button>
      <select name="cameraDevices" value={this.state.selectCameraDeviceID} onChange={(e) => this.setState({selectCameraDeviceID: e.target.value})}>
        {this.state.cameraDevices.map(device => (
          <option value={device.deviceId} key={device.deviceId}>{device.label}</option>
        ))}
      </select>
      <button onClick={this.setCamerDevice}>设置摄像头设备</button><span>{this.state.selectCameraDeviceID}</span>
      <br />
      <label htmlFor="recordingDeviceInterval">返音间隔：</label><input id="recordingDeviceInterval" type="number" value={this.state.recordingDeviceInterval} onChange={(e) => this.setState({recordingDeviceInterval: Number(e.target.value)})} />
      <label htmlFor="recordingDevicePlayback">是否返音：</label><select onChange={(e) => {this.setState({recordingDevicePlayback: e.target.value === "1"})}} value={this.state.recordingDevicePlayback ? "1" : "0"} name="recordingDevicePlayback" id="recordingDevicePlayback">
        <option value="1">是</option>
        <option value="0">否</option>
      </select>
      <button onClick={this.startRecordingDeviceTest}>开始麦克风测试</button>
      <button onClick={this.stopRecordingDeviceTest}>停止麦克风测试</button>
      <div>回调间隔： {this.state.recordingDeviceInterval}</div>
      <div>是否返音: {this.state.recordingDevicePlayback ? "是" : "否"}</div>
      { this.state.isStartRecordingDeviceTest && (<div>当前音量： {this.state.testMicVolume}</div>) }
      <br />
      <button onClick={this.startPlaybackDeviceTest}>开始扬声器测试</button>
      <button onClick={this.stopPlaybackDeviceTest}>停止扬声器测试</button>
    </div>;
  }
}

