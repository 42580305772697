import * as React from "react";
import { inject, observer } from 'mobx-react';
import { RoomStore } from "../../stores";
import { NONE } from "../../constants";
import { QNLocalVideoTrack, QNVideoOptimizationMode } from "qnweb-rtc";

interface State {
  shots: string[];
  selectedTrackID: string;
  optimizationMode: QNVideoOptimizationMode;
  result: boolean | "";
}

interface Props {
  room: RoomStore;
}

@observer
export default class VideoOptimizationModeControl extends React.Component<Props, State> {
  state: State = {
    shots: [],
    selectedTrackID: NONE,
    optimizationMode: QNVideoOptimizationMode.DEFAULT,
    result: ""
  };

  public render(): JSX.Element {
    return <div className="video-op-mode-container">
      <h2>Video Track Optimization Mode</h2>
      <label htmlFor="video-track-optimization-select">选择本地视频 track：</label>
      <select id="video-track-optimization-select" value={this.state.selectedTrackID} onChange={e => this.setState({ selectedTrackID: e.target.value })}>
        <option value={NONE}>-</option>
        {
          this.props.room.publishedTracks
            .filter(t => t.isVideo())
            .map(t => {
              return <option key={t.trackID} value={t.trackID}>{t.trackID}</option>;
            })
        }
      </select>
      <br />
      <label htmlFor="video-track-optimization-mode-select">选择 optimization mode：</label>
      <select id="video-track-optimization-mode-select" value={this.state.optimizationMode} onChange={e => this.setState({ optimizationMode: (e.target.value) as QNVideoOptimizationMode })}>
        <option value={QNVideoOptimizationMode.DEFAULT}>{QNVideoOptimizationMode.DEFAULT}</option>
        <option value={QNVideoOptimizationMode.DETAIL}>{QNVideoOptimizationMode.DETAIL}</option>
        <option value={QNVideoOptimizationMode.MOTION}>{QNVideoOptimizationMode.MOTION}</option>
      </select>
      <br />
      <button onClick={() => {
        const t = this.props.room.publishedTracks.find(t => t.trackID === this.state.selectedTrackID);
        if (!t) return;
        const result = (t as QNLocalVideoTrack).setOptimizationMode(this.state.optimizationMode);
        this.setState({ result });
      }}>setOptimizationMode</button>
      <span>result: {String(this.state.result)}</span>
    </div>;
  }
}

