import * as React from "react";
import { observer } from 'mobx-react';

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";
import { QNLiveStreamingState } from "qnweb-rtc";

interface State {
  startStreamID: string;
  url: string;
  stopStreamID: string;
  audioTrackID: string;
  videoTrackID: string;
  livestreamingState: { [key: string]: string; };
  sei: string;
}

interface Props {
  room: RoomStore;
}

@observer
export default class DirectLiveStreaming extends React.Component<Props, State> {
  state: State = {
    startStreamID: "",
    url: "rtmp://pili-publish.qnsdk.com/sdk-live/sdk-direct-livestreaming-demo",
    stopStreamID: "",
    sei: "",
    audioTrackID: NONE,
    videoTrackID: NONE,
    livestreamingState: {}
  };

  updateLivestreamState = (streamID: string, state: QNLiveStreamingState) => {
    this.setState({ livestreamingState: { ...this.state.livestreamingState, [streamID]: state } });
  };

  componentDidMount() {
    this.props.room.rtcClient.on("direct-livestreaming-state-changed", this.updateLivestreamState);
  }

  componentWillUnmount() {
    this.props.room.rtcClient.off("direct-livestreaming-state-changed", this.updateLivestreamState);
  }

  startDirectLiveStreaming = () => {
    const audioTrack = this.props.room.audioTracks.filter(t => t.trackID === this.state.audioTrackID)[0];
    const videoTrack = this.props.room.videoTracks.filter(t => t.trackID === this.state.videoTrackID)[0];
    if (!audioTrack && !videoTrack) {
      console.error(`必须要有一路 track, audioTrackID: ${this.state.audioTrackID}, videoTrackID: ${this.state.videoTrackID}`);
      return;
    }

    this.props.room.rtcClient.startDirectLiveStreaming({
      userConfigExtraInfo: this.state.sei,
      streamID: this.state.startStreamID,
      url: this.state.url,
      audioTrack: audioTrack,
      videoTrack: videoTrack,
    });
  };

  stopDirectLiveStreaming = () => {
    this.props.room.rtcClient.stopDirectLiveStreaming(this.state.stopStreamID);
  };

  public render(): JSX.Element {
    return <div className="direct-livestreaming-container">
      <h2>单路转推</h2>
      <label htmlFor="direct-livestreaming-start-streamID">streamID: </label>
      <input type="text" id="direct-livestreaming-start-streamID" value={this.state.startStreamID} onChange={e => this.setState({ startStreamID: e.target.value })} />
      <br />
      <label htmlFor="direct-livestreaming-url">url: </label>
      <input type="text" id="direct-livestreaming-url" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} />
      <br />
      <label htmlFor="direct-livestreaming-sei">sei: </label>
      <input type="text" id="direct-livestreaming-sei" value={this.state.sei} onChange={e => this.setState({ sei: e.target.value })} />
      <br />
      <label htmlFor="direct-livestreaming-audioTrackID">audioTrack: </label>
      <select id="direct-livestreaming-audioTrackID" value={this.state.audioTrackID} onChange={e => this.setState({ audioTrackID: e.target.value })}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.audioTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <br />
      <label htmlFor="direct-livestreaming-videoTrackID">videoTrack: </label>
      <select id="direct-livestreaming-videoTrackID" value={this.state.videoTrackID} onChange={e => this.setState({ videoTrackID: e.target.value })}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.videoTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <br />
      <button onClick={this.startDirectLiveStreaming}>开始转推</button>
      <br />
      <label htmlFor="direct-livestreaming-stop-streamID">streamID: </label>
      <input type="text" id="direct-livestreaming-stop-streamID" value={this.state.stopStreamID} onChange={e => this.setState({ stopStreamID: e.target.value })} />
      <br />
      <button onClick={this.stopDirectLiveStreaming}>停止转推</button>
      <div className="direct-livestreaming-state">
        <div>转推状态：</div>
        {
          Object.entries(this.state.livestreamingState)
            .map(([streamID, state]) => {
              return <div key={streamID}>{streamID}: {state}</div>;
            })
        }
      </div>
    </div>;
  }
}

