export const RTC_APP_ID = 'd8dre8w1p';
export const HOST = 'https://api-demo.qnsdk.com';
export const PREFIX = '/v1';

export const API = {
  LIST_ROOM: `/rtc/rooms/app/${RTC_APP_ID}`,
  LIST_USERS: (appid: string, roomName: string) => `/rtc/users/app/${appid || RTC_APP_ID}/room/${roomName}`,
  GET_APP_CONFIG: (appid?: string) => `/rtc/app/${appid || RTC_APP_ID}`,
  JOIN_ROOM_TOKEN: (roomName: string, user: string, appid?: string) => `/rtc/token/app/${appid || RTC_APP_ID}/room/${roomName}/user/${user}`,
  CREATE_ROOM_TOKEN: (roomName: string, user: string, appid?: string) => `/rtc/token/admin/app/${appid || RTC_APP_ID}/room/${roomName}/user/${user}`,
  GET_RTMP: (key: string) => `/live/stream/${key}`
};