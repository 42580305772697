import * as React from "react";
import { inject, observer } from 'mobx-react';
import { RoomStore } from "../../stores";
import { NONE } from "../../constants";
import { QNLocalVideoTrack, QNRemoteVideoTrack } from "qnweb-rtc";

interface State {
  shots: string[];
  selectedTrackID: string;
}

interface Props {
  room: RoomStore;
}

@observer
export default class TrackShotsControl extends React.Component<Props, State> {
  state: State = {
    shots: [],
    selectedTrackID: NONE
  };

  public render(): JSX.Element {
    return <div className="track-shots-control-container">
      <h2>Track Shots</h2>
      <label htmlFor="track-shots-select">选择视频 track：</label>
      <select id="track-shots-select" value={this.state.selectedTrackID} onChange={e => this.setState({ selectedTrackID: e.target.value })}>
        <option value={NONE}>-</option>
        {
          this.props.room.videoTracks
            .filter(t => {
              if (t instanceof QNRemoteVideoTrack && t.isSubscribed()) {
                return true;
              }
              if (t instanceof QNLocalVideoTrack) {
                return true;
              }
              return false;
            })
            .map(t => {
              return <option key={t.trackID} value={t.trackID}>{t.trackID}</option>;
            })
        }
      </select>
      <button onClick={() => {
        const t = this.props.room.videoTracks.find(t => t.trackID === this.state.selectedTrackID);
        if (!t) return;
        this.setState({ shots: [...this.state.shots, (t as QNLocalVideoTrack).getCurrentFrameData()] });
      }}>截图</button>
      <br />
      {
        this.state.shots.map((s, i) => {
          return <img style={{ width: "320px", height: "240px" }} src={s} key={i} />;
        })
      }
    </div>;
  }
}

