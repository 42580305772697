import * as React from "react";
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores/roomStore";
import { QNCustomMessage } from "qnweb-rtc";
import { randomStringGen } from "../../utils";

interface Message {
  sender: string;
  receiver: string;
  text: string;
}

interface Props {
  room: RoomStore;
}

interface State {
  currentMsg: string;
  selectedUserIds: string[];
  messages: Message[];
}

@observer
export default class TextMessages extends React.Component<Props, State> {
  state: State = {
    currentMsg: "",
    selectedUserIds: [],
    messages: [],
  };

  updateMessages = (customMessage: QNCustomMessage) => {
    this.setState({
      messages: [
        ...this.state.messages,
        {
          sender: customMessage.userID,
          receiver: this.props.room.userID,
          text: customMessage.content
        }
      ]
    });
  };

  componentDidMount() {
    this.props.room.rtcClient.on("message-received", this.updateMessages);
  }

  componentWillUnmount() {
    this.props.room.rtcClient.off("message-received", this.updateMessages);
  }

  private handleUpdateSelectedUserIds = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedUserIds = [];
    for (let i = 0; i < e.target.selectedOptions.length; i += 1) {
      const option = e.target.selectedOptions.item(i);
      if (!option) continue;
      selectedUserIds.push(option.value);
    }
    this.setState({ selectedUserIds });
  };

  private handleSendMessage = () => {
    const users = this.state.selectedUserIds
      .map(id => this.props.room.remoteUsers.filter(user => user.userID === id)[0])
      .filter(user => !!user);
    this.props.room.rtcClient.sendMessage(randomStringGen(8), this.state.currentMsg, users);
    this.setState({
      messages: [
        ...this.state.messages,
        {
          sender: this.props.room.userID,
          receiver: this.state.selectedUserIds.reduce((pre, cur) => pre + cur + ", ", ""),
          text: this.state.currentMsg
        }
      ]
    });
    this.setState({ currentMsg: "" });
  };

  public render(): JSX.Element {
    return <div className="text-messages-container">
      <h2>文本消息</h2>
      <label>输入消息：</label>
      <input
        type="text"
        placeholder="输入要发送的文本消息"
        value={this.state.currentMsg}
        onChange={e => this.setState({ currentMsg: e.target.value })}
      />
      <br />
      <label>选择收信人：</label>
      <select id="sel-message-userid-list" multiple onChange={this.handleUpdateSelectedUserIds} value={this.state.selectedUserIds}>
        {this.props.room.remoteUsers.map(user => (
          <option key={user.userID} value={user.userID}>{user.userID}</option>
        ))}
      </select>
      <button onClick={this.handleSendMessage}>发送</button>
      <p>聊天记录：</p>
      <div className="messages-container">
        {this.state.messages.map((message, index) => {
          return <p key={index} >用户 {message.sender} 发送给用户 {message.receiver}：{message.text}</p>;
        })}
      </div>
    </div>;
  }
}

