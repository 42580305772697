//@ts-nocheck
import * as React from "react";
import "./style.css";

import { observer } from 'mobx-react';
import { AudioWave } from '../AudioWave';
import { QNLocalAudioTrack, QNLocalTrack, QNRemoteAudioTrack, QNRemoteVideoTrack, QNTrack, QNTrackProfile } from "qnweb-rtc";

interface Props {
  track: QNTrack;
  isSelf: boolean;
  mirror: boolean;
}

interface State {
  stats: any[];
  interval?: NodeJS.Timeout;
  profile: QNTrackProfile;
  lowStreamState: boolean;
  isMuted: boolean; // local track，当前状态
  remoteIsMuted: boolean; // remote track，当前状态
}

@observer
export default class TrackPlayer extends React.Component<Props, State> {
  public state: State = {
    stats: [],
    profile: QNTrackProfile.HIGH,
    lowStreamState: false,
    isMuted: false,
    remoteIsMuted: false,
  };

  private container?: HTMLDivElement;

  public updateProfile = (profile: QNTrackProfile) => {
    this.setState({ profile });
  };

  public updateIsMuted = (isMuted: boolean) => {
    this.setState({ isMuted });
  };

  public updateMuteState = (isMuted: boolean) => {
    this.setState({ remoteIsMuted: isMuted });
  };

  public updateLowStreamState = (state: boolean) => {
    this.setState({lowStreamState: state})
  }

  public componentDidMount(): void {
    const interval = setInterval(() => {
      if (this.props.isSelf && this.props.track.isVideo()) {
        const stats = (this.props.track as any).getStats();
        stats.forEach((stat: any) => {
          Object.keys(stat).forEach((key: string) => {
            if (key === "downlinkBitrate" || key === "uplinkBitrate") {
              stat[key] = Math.floor(stat[key] / 1000);
            }
          });
        });
        this.setState({ stats });
      } else {
        const stats = [(this.props.track as any).getStats()];
        stats.forEach((stat: any) => {
          Object.keys(stat).forEach((key: string) => {
            if (key === "downlinkBitrate" || key === "uplinkBitrate") {
              stat[key] = Math.floor(stat[key] / 1000);
            }
          });
        });
        this.setState({ stats });
      }
    }, 800);
    this.setState({ interval });
    this.props.track.on("profile-changed", this.updateProfile);
    this.props.track.on("mute-state-changed", this.updateMuteState);
    this.props.track.on("low-stream-state-changed", this.updateLowStreamState);
  }

  public componentWillUnmount(): void {
    if (this.state.interval) clearInterval(this.state.interval);
    this.props.track.off("profile-changed", this.updateProfile);
    this.props.track.off("mute-state-changed", this.updateMuteState);
  }

  private handlePlayerDom = (ref: HTMLDivElement | null) => {
    if (this.container || !ref) return;
    this.container = ref;
    if (this.props.isSelf && this.props.track.isAudio()) return;
    this.props.track.play(this.container, { mirror: this.props.mirror });
  };

  private handleSetProfile(profile: QNTrackProfile) {
    (this.props.track as QNRemoteVideoTrack).setProfile(profile);
  }

  private handleSetLowStream(lowStream: boolean) {
    (this.props.track as QNRemoteVideoTrack).setLowStream(lowStream);
  }

  private handleSetMuted(isMuted: boolean) {
    (this.props.track as QNLocalTrack).setMuted(isMuted);
    this.setState({ isMuted: this.props.track.isMuted() });
  }

  public render(): JSX.Element {
    return (
      <div className="track-container" id={`track-${this.props.track.trackID}`}>
        <div className="track__player" ref={this.handlePlayerDom}></div>
        <p className="track__id">{this.props.track.trackID}</p>
        <div className="track__info">
          {this.state.stats.map((stat, index) => {
            return <div className="track__info__item" key={index}>
              {
                Object.entries(stat).map((s: any, index: number) => {
                  return <div key={index}><span>{s[0]}</span>: {s[1]}</div>;
                })
              }
            </div>;
          })}
        </div>
        <div className="track__control">
          {
            !this.props.isSelf && this.props.track.isVideo() &&
            <div className="track__profile">
              {
                (this.props.track as QNRemoteVideoTrack).isMultiProfileEnabled && <>
                  <button onClick={() => this.handleSetProfile(QNTrackProfile.HIGH)}>HIGH</button>
                  <button onClick={() => this.handleSetProfile(QNTrackProfile.MEDIUM)}>MEDIUM</button>
                  <button onClick={() => this.handleSetProfile(QNTrackProfile.LOW)}>LOW</button>
                  <span>{this.state.profile}</span>
                </>
              }
              {
                (this.props.track as QNRemoteVideoTrack).isLowStreamEnabled && <>
                  <br />
                  <button onClick={() => this.handleSetLowStream(false)}>大流</button>
                  <button onClick={() => this.handleSetLowStream(true)}>小流</button>
                  <span>{String(this.state.lowStreamState)}</span>
                </>
              }
            </div>
          }
          {
            this.props.isSelf && <div className="track__mute">
              <label htmlFor="track-set-muted">isMuted: </label>
              <input type="checkbox" id="track-set-muted" checked={this.state.isMuted} onChange={e => this.handleSetMuted(e.target.checked)} />
            </div>
          }
          {
            !this.props.isSelf && <div className="track-mute-state">isMuted: {String(this.state.remoteIsMuted)}</div>
          }
        </div>
        {this.props.track.isAudio() && <div className="track__audio">
          {
            this.props.isSelf ? <AudioWave width={320} height={240} color="#66ccff" track={this.props.track as QNLocalAudioTrack} /> :
              <AudioWave width={320} height={240} color="#66ccff" track={this.props.track as QNRemoteAudioTrack} />
          }
        </div>}
      </div>
    );
  }
}
