import * as React from "react";
import { inject, observer } from 'mobx-react';
import { NONE } from "../../constants";
import QNRTC, { QNElectronScreenSource, QNElectronScreenSourceType } from "qnweb-rtc";

interface State {
  sources: QNElectronScreenSource[];
  selectedSourceID: string;
  screenTypeChecked: boolean;
  windowTypeChecked: boolean;
}

interface Props {
  pubFunc: (e?: { electronScreenSourceID?: string; }) => void;
}

const isElectron = navigator.userAgent.toLowerCase().indexOf("electron") > -1;

@observer
export default class ElectronScreenCapture extends React.Component<Props, State> {
  state: State = {
    sources: [],
    selectedSourceID: NONE,
    screenTypeChecked: true,
    windowTypeChecked: true
  };

  componentDidMount() {
    this.updateSources();
  }

  async updateSources() {
    if (!isElectron) return;
    let sources: QNElectronScreenSource[];
    try {
      if (this.state.screenTypeChecked && this.state.windowTypeChecked) {
        sources = await QNRTC.getElectronScreenSources(QNElectronScreenSourceType.ALL);
      } else if (this.state.screenTypeChecked) {
        sources = await QNRTC.getElectronScreenSources(QNElectronScreenSourceType.SCREEN);
      } else if (this.state.windowTypeChecked) {
        sources = await QNRTC.getElectronScreenSources(QNElectronScreenSourceType.WINDOW);
      } else {
        sources = [];
      }
      this.setState({ sources });
    } catch (e) {
      console.log("QNRTC.getElectronScreenSources error", e);
    }
  }

  updateScreenTypeChecked(checked: boolean) {
    this.setState({ screenTypeChecked: checked }, this.updateSources);
  }

  updateWindowTypeChecked(checked: boolean) {
    this.setState({ windowTypeChecked: checked }, this.updateSources);
  }

  public render(): JSX.Element {
    return <div className="electron-screen-capture-container">
      <h2>Electron Screen Capture</h2>
      {!isElectron && <div>非 Electron 环境，忽略</div>}
      {isElectron && <div>
        <div>source type:
          <label>screen:
            <input type="checkbox" checked={this.state.screenTypeChecked} onChange={e => this.updateScreenTypeChecked(e.target.checked)} />
          </label>
          <label>window:
            <input type="checkbox" checked={this.state.windowTypeChecked} onChange={e => this.updateWindowTypeChecked(e.target.checked)} />
          </label>
        </div>
        <label>Electron Screen Source:
          <select value={this.state.selectedSourceID} onChange={e => this.setState({ selectedSourceID: e.target.value })}>
            <option value={NONE}>-</option>
            {
              this.state.sources.map(source => {
                return <option key={source.ID} value={source.ID}>{source.name}</option>;
              })
            }
          </select>
        </label>
        <button onClick={() => {
          this.props.pubFunc({ electronScreenSourceID: this.state.selectedSourceID });
        }}>Name选择发布</button>
        <br />
        <button onClick={() => {
          this.props.pubFunc();
        }}>UI选择发布</button>
      </div>
      }
    </div>;
  }
}

