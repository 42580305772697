import * as React from 'react';
import './App.css';
import { History } from 'history';
import { Root } from './containers/Root';
import { Router, Route, Switch, Redirect } from "react-router";
import { HomePage } from './containers/HomePage';
import { RoomPage } from './containers/RoomPage';
import QNRTC from 'qnweb-rtc';

interface Props {
  history: History;
}

class App extends React.Component<Props> {

  componentDidMount() {
    if (!QNRTC.isBrowserSupported()) {
      alert("当前环境不支持 qnweb-rtc");
    }
  }
  public render(): JSX.Element {
    return (
      <Root>
        <Router history={this.props.history}>
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/room" component={RoomPage} exact />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </Root>
    );
  }
}

export default App;
