import * as React from "react";
import { observer } from 'mobx-react';

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";
import QNRTC from "qnweb-rtc";

interface State {
  audioTrackID: string;
  audioBufferAttrs: string;
  audioBufferList: AudioBuffer[];
  scriptNode: ScriptProcessorNode | null;
}

interface Props {
  room: RoomStore;
}

@observer
export default class AudioBufferControl extends React.Component<Props, State> {
  state: State = {
    audioTrackID: NONE,
    audioBufferAttrs: "",
    audioBufferList: [],
    scriptNode: null
  };

  handleAudioFrame = (buffer: AudioBuffer) => {
    this.state.audioBufferList.push(buffer);
    this.setState({
      audioBufferAttrs: JSON.stringify({
        now: Date.now(),
        duration: buffer.duration,
        length: buffer.length,
        numberOfChannels: buffer.numberOfChannels,
        sampleRate: buffer.sampleRate,
      })
    });

  };

  handleCloseAudioStream = () => {
    if (this.state.scriptNode) {
      this.state.scriptNode.disconnect();
    }
  }

  handleCreateAudioStream = () => {
    const audioContext = new AudioContext();
    const scriptNode = audioContext.createScriptProcessor(4096, 2, 2);
    this.setState({
      scriptNode
    });
    scriptNode.addEventListener("audioprocess", async (event) => {
      const buffer = this.state.audioBufferList.shift();
      if (buffer !== undefined) {
        const outputBufferLeft = event.outputBuffer.getChannelData(0);
        const outputBufferRight = event.outputBuffer.getChannelData(1);
        for (let i = 0; i < outputBufferLeft.length; i ++) {
          outputBufferLeft[i] = buffer.getChannelData(0)[i];
        }
        for (let i = 0; i < outputBufferRight.length; i ++) {
          const channelData = buffer.numberOfChannels === 1 ? buffer.getChannelData(0) : buffer.getChannelData(1);
          outputBufferRight[i] = channelData[i];
        }
      }
    })
    scriptNode.connect(audioContext.destination);
  }

  handleChangeAudioTrack = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lastTrack: any = this.props.room.audioTracks.find(t => t.trackID === this.state.audioTrackID);
    if (lastTrack) {
      this.handleCloseAudioStream();
      lastTrack.off("audioFrame", this.handleAudioFrame);
    }
    this.setState({ audioTrackID: e.target.value });
    const curTrack: any = this.props.room.audioTracks.find(t => t.trackID === e.target.value);
    if (curTrack) {
      this.handleCreateAudioStream();
      curTrack.on("audioFrame", this.handleAudioFrame);
    }
  };

  handleForceAudioWorklet = () => {
    QNRTC.forceAudioWorklet = true
  }

  handleForceScriptNode = () => {
    QNRTC.forceAudioWorklet = false
  }

  public render(): JSX.Element {
    return <div className="audio-buffer-control-container">
      <h2>AudioBuffer</h2>
      <label htmlFor="audio-buffer-control-audioTrackID">audioTrack: </label>
      <button onClick={() => this.handleForceAudioWorklet()}>开启 audioWorklet</button>
      <button onClick={() => this.handleForceScriptNode()}>开启 scriptNode</button>
      <select id="audio-buffer-control-audioTrackID" value={this.state.audioTrackID} onChange={e => this.handleChangeAudioTrack(e)}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.audioTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <div>audioBuffer state: {this.state.audioBufferAttrs}</div>
    </div>;
  }
}

