import * as React from "react";
import { observer } from 'mobx-react';
import { API, NONE } from "../../constants";
import { QNMediaRelayState, QNClientRole } from "qnweb-rtc";
import { RoomStore } from "../../stores";
import { request } from "../../utils";

type RoleOption = typeof NONE | QNClientRole;

interface Props {
    room: RoomStore;
}

interface State {
    role: RoleOption;
    setRoleResult: string;
    destRoomNames: string[];
    startRelayResult: string;
    updateRelayResult: string;
    stopRelayResult: string;
    relayStates: [string, QNMediaRelayState][];
}

@observer
export default class MediaRelay extends React.Component<Props> {
    state: State = {
        role: NONE,
        setRoleResult: "",
        destRoomNames: [],
        startRelayResult: "",
        updateRelayResult: "",
        stopRelayResult: "",
        relayStates: [],
    };

    handleRelayStateChanged = (roomName: string, state: QNMediaRelayState) => {
        this.setState({ relayStates: [...this.state.relayStates, [roomName, state]] });
    };

    componentDidMount() {
        this.props.room.rtcClient.on("media-relay-state-changed", this.handleRelayStateChanged);
    }

    componentWillUnmount() {
        this.props.room.rtcClient.off("media-relay-state-changed", this.handleRelayStateChanged);
    }

    handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const role = e.target.value as RoleOption;
        if (role !== NONE) {
            this.setState({ setRoleResult: "waiting" });
            this.props.room.rtcClient.setClientRole(role)
                .then(() => {
                    this.setState({ setRoleResult: "success" });
                    this.setState({ role });
                })
                .catch(e => {
                    this.setState({ setRoleResult: e.message });
                });
        }
    };

    handleDestRoomChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        this.setState({
            destRoomNames: [
                ...this.state.destRoomNames.slice(0, index),
                e.target.value,
                ...this.state.destRoomNames.slice(index + 1)]
        });
    };

    handleAddDestRoom = () => {
        this.setState({ destRoomNames: [...this.state.destRoomNames, ""] });
    };

    handleRemoveDestRoom = (index: number) => {
        this.setState({ destRoomNames: [...this.state.destRoomNames.slice(0, index), ...this.state.destRoomNames.slice(index + 1)] });
    };

    fetchDestRoomInfos = async () => {
        return await Promise.all(this.state.destRoomNames.map(roomName => {
            const requestURL = `${API.JOIN_ROOM_TOKEN(roomName, this.props.room.userID, this.props.room.appID)}?bundleId=demo-rtc.qnsdk.com`;
            return request(requestURL, "text").then(roomToken => ({ roomName, roomToken }));
        }));
    };

    handleStartRelay = async () => {
        try {
            const destRoomInfos = await this.fetchDestRoomInfos();
            const res = await this.props.room.rtcClient.startMediaRelay({ destRoomInfos });
            this.setState({ startRelayResult: JSON.stringify(Object.entries(res).map(([key, value]) => ([key, this.convertRelayState(value)]))) });
        } catch (e) {
            this.setState({ startRelayResult: e instanceof Error ? e.message : String(e) });
        }
    };

    handleUpdateRelay = async () => {
        try {
            const destRoomInfos = await this.fetchDestRoomInfos();
            const res = await this.props.room.rtcClient.updateMediaRelay({ destRoomInfos });
            this.setState({ updateRelayResult: JSON.stringify(Object.entries(res).map(([key, value]) => ([key, this.convertRelayState(value)]))) });
        } catch (e) {
            this.setState({ updateRelayResult: e instanceof Error ? e.message : String(e) });
        }
    };

    handleStopRelay = async () => {
        try {
            const res = await this.props.room.rtcClient.stopMediaRelay();
            this.setState({ stopRelayResult: JSON.stringify(Object.entries(res).map(([key, value]) => ([key, this.convertRelayState(value)]))) });
        } catch (e) {
            this.setState({ stopRelayResult: e instanceof Error ? e.message : String(e) });
        }
    };

    convertRelayState = (state: QNMediaRelayState) => {
        switch (state) {
            case QNMediaRelayState.SUCCESS:
                return "success";
            case QNMediaRelayState.STOPPED:
                return "stopped";
            case QNMediaRelayState.INVALID_TOKEN:
                return "invalid token";
            case QNMediaRelayState.NO_ROOM:
                return "no room";
            case QNMediaRelayState.ROOM_CLOSED:
                return "room closed";
            case QNMediaRelayState.PLAYER_EXISTED:
                return "player existed";
            default:
                throw new Error("unexpected relay state: " + state);
        }
    };

    public render(): JSX.Element {
        return <div>
            <h2>Media Relay</h2>
            <div>
                <label>Change Role: </label>
                <select value={this.state.role} onChange={this.handleRoleChange}>
                    <option value={NONE}>{NONE}</option>
                    <option value={QNClientRole.BROADCASTER}>{QNClientRole.BROADCASTER}</option>;
                    <option value={QNClientRole.AUDIENCE}>{QNClientRole.AUDIENCE}</option>;
                </select>
                <span>result: {this.state.setRoleResult}</span>
            </div>
            <div>
                <h3>dest room names: </h3>
                {this.state.destRoomNames.map((roomName, index) => {
                    return <div key={index}>
                        <label>dest room name: </label>
                        <input type="text" value={roomName} onChange={e => this.handleDestRoomChange(e, index)} />
                        <button onClick={() => this.handleRemoveDestRoom(index)}>remove</button>
                    </div>;
                })}
                <button onClick={this.handleAddDestRoom}>add</button>
                <div>
                    <button onClick={this.handleStartRelay}>start</button>
                    <span>result: {this.state.startRelayResult}</span>
                </div>
                <div>
                    <button onClick={this.handleUpdateRelay}>update</button>
                    <span>result: {this.state.updateRelayResult}</span>
                </div>
                <div>
                    <button onClick={this.handleStopRelay}>stop</button>
                    <span>result: {this.state.stopRelayResult} </span>
                </div>
                <div>
                    <div>state:</div>
                    <div>
                        {this.state.relayStates.map(relayState => {
                            return <div key={relayState[0]+"-"+relayState[1]}>
                                <span>{relayState[0]}: {this.convertRelayState(relayState[1])}</span>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
        </div>;
    }
}

