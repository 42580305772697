import * as React from "react";
import { observer } from 'mobx-react';
import { NONE } from "../../constants";
import QNRTC, { QNCameraVideoTrack, QNLocalAudioTrack, QNLocalVideoTrack, QNMicrophoneAudioTrack, QNRemoteAudioTrack, QNRemoteVideoTrack, QNTrack } from "qnweb-rtc";


interface Props {
  cameras: MediaDeviceInfo[];
  microphones: MediaDeviceInfo[];
  playbackDevices: MediaDeviceInfo[];
  audioTracks: QNTrack[];
  handlePub: (track: QNCameraVideoTrack | QNMicrophoneAudioTrack) => Promise<void>;
}

interface State {
  currentCamera: string;
  currentMicrophone: string;
  currentPlaybackDevice: string;
  currentAudioTrackID: string;
}

@observer
export default class MediaDevices extends React.Component<Props> {
  state: State = {
    currentCamera: NONE,
    currentMicrophone: NONE,
    currentPlaybackDevice: NONE,
    currentAudioTrackID: NONE,
  };

  handlePubCamera = () => {
    let cameraId: any = undefined;
    if (this.state.currentCamera !== NONE) {
      cameraId = this.state.currentCamera;
    }
    QNRTC.createCameraVideoTrack({ cameraId })
      .then((track) => {
        return this.props.handlePub(track);
      })
      .then(() => {
        console.log("publish camera track with deviceId: ", cameraId);
      })
      .catch(e => {
        console.error(e);
      });
  };

  handlePubMic = () => {
    if (this.state.currentMicrophone === NONE) {
      console.error("no microphone selected")
      return
    }
    QNRTC.createMicrophoneAudioTrack({ microphoneId: this.state.currentMicrophone })
      .then((track) => {
        return this.props.handlePub(track);
      })
      .then(() => {
        console.log("publish camera track with micphoneId: ", this.state.currentMicrophone);
      })
      .catch(e => {
        console.error(e);
      });
  };

  handleSetPlaybackDevice = () => {
    if (this.state.currentAudioTrackID === NONE) {
      console.error("No audio track selected");
      return;
    }
    if (this.state.currentPlaybackDevice === NONE) {
      console.error("No playbackDevice selected");
      return;
    }
    const track = this.props.audioTracks.find(t => t.trackID === this.state.currentAudioTrackID);
    if (!track) {
      console.error("cannot find target track calling handleSetPlaybackDevice", this.state.currentAudioTrackID);
      return;
    }

    (track as any).setPlaybackDevice(this.state.currentPlaybackDevice)
      .then(() => {
        console.log("setPlaybackDevice success", this.state.currentPlaybackDevice);
      })
      .catch((e: any) => {
        console.log("setPlaybackDevice fail", this.state.currentPlaybackDevice, e);
      });
  };

  public render(): JSX.Element {
    return <div>
      <div>
        <h3>Media Devices</h3>
        <span>Cameras</span>
        <select value={this.state.currentCamera} onChange={e => this.setState({ currentCamera: e.target.value })}>
          {this.props.cameras.map(device => {
            const deviceStr = `deviceId: ${device.deviceId}`;
            return <option key={deviceStr} value={device.deviceId}>{deviceStr}</option>;
          })}
          <option key={NONE} value={NONE}>{NONE}</option>;
        </select>
        <br />
        <button onClick={this.handlePubCamera}>发布指定摄像头 Track</button>
      </div>
      <div className="microphones-device-container">
        <span>Microphones</span>
        <select className="microphones-device-select" value={this.state.currentMicrophone} onChange={e => this.setState({ currentMicrophone: e.target.value })}>
          {this.props.microphones.map(device => {
            const deviceStr = `deviceId: ${device.deviceId}`;
            return <option key={deviceStr} value={device.deviceId}>{deviceStr}</option>;
          })}
          <option key={NONE} value={NONE}>{NONE}</option>;
        </select>
        <br />
        <button onClick={this.handlePubMic}>发布指定麦克风 Track</button>
      </div>
      <div className="playbacks-device-container">
        <span>Playback Devices</span>
        <select className="playbacks-device-select" value={this.state.currentPlaybackDevice} onChange={e => this.setState({ currentPlaybackDevice: e.target.value })}>
          {this.props.playbackDevices.map(device => {
            const deviceStr = `deviceId: ${device.deviceId}`;
            return <option key={deviceStr} value={device.deviceId}>{deviceStr}</option>;
          })}
          <option key={NONE} value={NONE}>{NONE}</option>;
        </select>
        <br />
        <span>audio tracks</span>
        <select id="playback-control-audioTrackID" value={this.state.currentAudioTrackID} onChange={e => this.setState({ currentAudioTrackID: e.target.value })}>
          <option value={NONE}>{NONE}</option>
          {this.props.audioTracks.map(t => (
            <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
          ))}
        </select>
        <br />
        <button onClick={this.handleSetPlaybackDevice}>setPlaybackDevice</button>
      </div>
    </div>;
  }
}

