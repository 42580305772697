export * from "./request";

/**
 * new 代表缺少的元素
 * old 代表多余的元素
 */
interface CompareArrayResult<T, K> {
  new: K[];
  old: T[];
}

/**
 * 通过提供的 compareFunc 比较 2 个数组 array1, array2
 * 返回 array1 相比于 array2 缺少的元素 和 array1 相比于 array2 多余的元素
 */
export function compareArray<T, K>(array1: T[], array2: K[], compareFunc: (a: T, b: K) => boolean): CompareArrayResult<T, K> {
  const newArray: K[] = [];
  const oldArray: T[] = [];

  for (const item of array1) {
    let flag = false;
    for (const item2 of array2) {
      if (compareFunc(item, item2)) {
        flag = true;
      }
    }
    if (!flag) oldArray.push(item);
  }

  for (const item of array2) {
    let flag = false;
    for (const item2 of array1) {
      if (compareFunc(item2, item)) {
        flag = true;
      }
    }
    if (!flag) newArray.push(item);
  }

  return { new: newArray, old: oldArray };
}

export function formatSecToTimeString(sec: number): string {
  const minNum = Math.floor(sec / 60);
  const secNum = Math.round(sec - (minNum * 60));

  return `${minNum < 10 ? '0' + minNum : minNum}:${secNum < 10 ? '0' + secNum : secNum}`;
}


export function intervalLog(interval = 1000) {
  let intervalId: any;
  let intervalValue: any[] = [];

  function log(value: any) {
    intervalValue.push(value);
    
    if (!intervalId) {
      intervalId = setInterval(() => {
        if (intervalValue.length > 0) {
          console.log(intervalValue);
          intervalValue = [];
        } else {
          clearInterval(intervalId);
          intervalId = undefined;
        }
      }, interval);
    }
  }
  return log;
}

export function randomStringGen(strLength: number = 5): string {
  const arr = new Uint8Array((strLength || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

function dec2hex (dec: number): string {
  return ("0" + dec.toString(16)).substr(-2);
}
