import React from 'react';
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores";
import { QNVolumeIndicator } from "qnweb-rtc";

interface State {
  volumeIndicatorHistory: Array<QNVolumeIndicator>;
}

interface Props {
  room: RoomStore;
}

@observer
export default class VolumeIndicatorControl extends React.Component<Props, State> {
  state: State = {
    volumeIndicatorHistory: []
  };
  componentDidMount() {
    this.props.room.rtcClient.on("volume-indicator", this.audioVolumeIndicatorCallback)
  };
  componentWillUnmount() {
    this.props.room.rtcClient.off("volume-indicator", this.audioVolumeIndicatorCallback)
  };
  audioVolumeIndicatorCallback = (volumes: QNVolumeIndicator[]) => {
    this.state.volumeIndicatorHistory = volumes;
    this.setState(() => {
      return {
        volumeIndicatorHistory :volumes
      }
    });
  };
  enableAudioVolumeIndicator = () => {
    this.props.room.rtcClient.enableAudioVolumeIndicator()
  };

  public render(): JSX.Element {
    return <div className="volume-indicator-control-container">
      <h2>音量检测</h2>
      <button onClick={() => this.enableAudioVolumeIndicator()}>开启检测</button>
      <div>
        {this.state.volumeIndicatorHistory.map(volume => (
          <p key={volume.trackID} >trackID：{volume.trackID}  userID：{volume.userID}  level： {volume.level}</p>
        ))}
      </div>
    </div>;
  }
}

