import * as React from "react";
import { observer } from 'mobx-react';

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";
import { QNLiveStreamingState, QNRenderMode, QNTranscodingLiveStreamingImage } from "qnweb-rtc";

interface State {
  startStreamID: string;
  updateStreamID: string;
  stopStreamID: string;
  url: string;
  width: number;
  height: number;
  videoFrameRate: number;
  bitrate: number;
  maxBitrate: number;
  minBitrate: number;
  renderMode: QNRenderMode;
  holdLastFrame: boolean;
  background: QNTranscodingLiveStreamingImage;
  watermarks: QNTranscodingLiveStreamingImage[];
  selectedTrack: string;
  trackX: number;
  trackY: number;
  trackZOrder: number;
  trackWidth: number;
  trackHeight: number;
  trackRenderMode: QNRenderMode;
  livestreamingState: { [key: string]: string; };
  sei: string;
}

interface Props {
  room: RoomStore;
}

@observer
export default class TranscodingLiveStreaming extends React.Component<Props, State> {
  state: State = {
    startStreamID: "",
    url: "rtmp://pili-publish.qnsdk.com/sdk-live/sdk-transcoding-livestreaming-demo",
    width: 600,
    height: 480,
    videoFrameRate: 25,
    bitrate: 1000,
    maxBitrate: 1000,
    minBitrate: 1000,
    renderMode: QNRenderMode.ASPECT_FILL,
    holdLastFrame: false,
    background: { url: "http://pili-playback.qnsdk.com/ivs_background_1280x720.png", x: 0, y: 0, width: 600, height: 480 },
    watermarks: [{ url: "", x: 0, y: 0, width: 100, height: 100 }],
    updateStreamID: "",
    selectedTrack: NONE,
    trackX: 0,
    trackY: 0,
    trackZOrder: 0,
    trackWidth: 100,
    trackHeight: 100,
    trackRenderMode: QNRenderMode.ASPECT_FILL,
    stopStreamID: "",
    livestreamingState: {},
    sei: ""
  };

  updateLivestreamState = (streamID: string, state: QNLiveStreamingState) => {
    this.setState({ livestreamingState: { ...this.state.livestreamingState, [streamID]: state } });
  };

  componentDidMount() {
    this.props.room.rtcClient.on("transcoding-livestreaming-state-changed", this.updateLivestreamState);
  }

  componentWillUnmount() {
    this.props.room.rtcClient.off("transcoding-livestreaming-state-changed", this.updateLivestreamState);
  }

  startTranscodingLiveStreaming = () => {
    this.props.room.rtcClient.startTranscodingLiveStreaming({
      streamID: this.state.startStreamID,
      url: this.state.url,
      width: this.state.width,
      height: this.state.height,
      videoFrameRate: this.state.videoFrameRate,
      bitrate: this.state.bitrate,
      maxBitrate: this.state.maxBitrate,
      minBitrate: this.state.minBitrate,
      renderMode: this.state.renderMode,
      holdLastFrame: this.state.holdLastFrame,
      background: !!this.state.background.url ? this.state.background : undefined,
      watermarks: this.state.watermarks.filter(watermark => watermark.url)
    });
  };

  stopTranscodingLiveStreaming = () => {
    this.props.room.rtcClient.stopTranscodingLiveStreaming(this.state.stopStreamID);
  };

  handleSetTrack = () => {
    const mergeOpts = [{
      trackID: this.state.selectedTrack,
      x: this.state.trackX,
      y: this.state.trackY,
      width: this.state.trackWidth,
      height: this.state.trackHeight,
      zOrder: this.state.trackZOrder,
      renderMode: this.state.trackRenderMode
    }];
    if (this.state.updateStreamID === "") {
      this.props.room.rtcClient.setTranscodingLiveStreamingTracks(null, mergeOpts)
        .then(() => console.log("setTranscodingLiveStreamingTracks success", mergeOpts))
        .catch((e) => console.log("setTranscodingLiveStreamingTracks fail", mergeOpts, e));
    } else {
      this.props.room.rtcClient.setTranscodingLiveStreamingTracks(this.state.updateStreamID, mergeOpts)
        .then(() => console.log("setTranscodingLiveStreamingTracks success", this.state.updateStreamID, mergeOpts))
        .catch((e) => console.log("setTranscodingLiveStreamingTracks fail", this.state.updateStreamID, mergeOpts, e));
    }
  };

  handleRemoveTrack = () => {
    const mergeOpts = [{
      trackID: this.state.selectedTrack
    }];
    if (this.state.updateStreamID === "") {
      this.props.room.rtcClient.removeTranscodingLiveStreamingTracks(null, mergeOpts)
        .then(() => console.log("removeTranscodingLiveStreamingTracks success", mergeOpts))
        .catch((e) => console.log("removeTranscodingLiveStreamingTracks fail", mergeOpts, e));
    } else {
      this.props.room.rtcClient.removeTranscodingLiveStreamingTracks(this.state.updateStreamID, mergeOpts)
        .then(() => console.log("removeTranscodingLiveStreamingTracks success", this.state.updateStreamID, mergeOpts))
        .catch((e) => console.log("removeTranscodingLiveStreamingTracks fail", this.state.updateStreamID, mergeOpts, e));
    }
  };

  public render(): JSX.Element {
    return <div className="transcoding-livestreaming-container">
      <h2>合流转推</h2>
      <label htmlFor="transcoding-livestreaming-start-streamID">streamID: </label>
      <input type="text" id="transcoding-livestreaming-start-streamID" value={this.state.startStreamID} onChange={e => this.setState({ startStreamID: e.target.value })} />
      <br />
      <label htmlFor="transcoding-livestreaming-url">url: </label>
      <input type="text" id="transcoding-livestreaming-url" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} />
      <br />
      <label htmlFor="transcoding-livestreaming-width">width: </label>
      <input type="text" id="transcoding-livestreaming-width" value={this.state.width} onChange={e => this.setState({ width: Number(e.target.value) })} />
      <br />
      <label htmlFor="transcoding-livestreaming-heigth">height: </label>
      <input type="text" id="transcoding-livestreaming-height" value={this.state.height} onChange={e => this.setState({ height: Number(e.target.value) })} />
      <br />
      <label htmlFor="transcoding-livestreaming-videoFrameRate">videoFrameRate: </label>
      <input type="text" id="transcoding-livestreaming-videoFrameRate" value={this.state.videoFrameRate} onChange={e => this.setState({ videoFrameRate: Number(e.target.value) })} />
      <br />
      <label htmlFor="transcoding-livestreaming-bitrate">bitrate: </label>
      <input type="text" id="transcoding-livestreaming-bitrate" value={this.state.bitrate} onChange={e => this.setState({ bitrate: Number(e.target.value) })} />
      <br />
      <label htmlFor="transcoding-livestreaming-minBitrate">minBitrate: </label>
      <input type="text" id="transcoding-livestreaming-minBitrate" value={this.state.minBitrate} onChange={e => this.setState({ minBitrate: Number(e.target.value) })} />
      <br />
      <label htmlFor="transcoding-livestreaming-maxBitrate">maxBitrate: </label>
      <input type="text" id="transcoding-livestreaming-maxBitrate" value={this.state.maxBitrate} onChange={e => this.setState({ maxBitrate: Number(e.target.value) })} />
      <br />
      {/* <label htmlFor="direct-livestreaming-url">sei: </label>
      <input type="text" id="direct-livestreaming-url" value={this.state.sei} onChange={e => this.setState({ sei: e.target.value })} />
      <br /> */}
      <label htmlFor="transcoding-livestreaming-render-mode">renderMode: </label>
      <select id="transcoding-livestreaming-audioTrackID" value={this.state.renderMode} onChange={e => this.setState({ renderMode: (e.target.value as QNRenderMode) })}>
        <option value={QNRenderMode.ASPECT_FILL}>{QNRenderMode.ASPECT_FILL}</option>
        <option value={QNRenderMode.ASPECT_FIT}>{QNRenderMode.ASPECT_FIT}</option>
        <option value={QNRenderMode.FILL}>{QNRenderMode.FILL}</option>
      </select>
      <br />
      <label htmlFor="transcoding-livestreaming-holdlastframe">holdLastFrame: </label>
      <input type="checkbox" id="transcoding-livestreaming-holdlastframe" checked={this.state.holdLastFrame} onChange={e => this.setState({ holdLastFrame: e.target.checked })} />
      <br />
      <label>background: </label>
      <label htmlFor="transcoding-livestreaming-background-url">url: </label>
      <input type="text" id="transcoding-livestreaming-background-url" value={this.state.background.url} onChange={e => this.setState({ background: { ...this.state.background, url: e.target.value } })} />
      <br />
      <label htmlFor="transcoding-livestreaming-background-x">x: </label>
      <input type="text" id="transcoding-livestreaming-background-x" className="narrow-text-input" value={this.state.background.x} onChange={e => this.setState({ background: { ...this.state.background, x: Number(e.target.value) } })} />
      <label htmlFor="transcoding-livestreaming-background-y">y: </label>
      <input type="text" id="transcoding-livestreaming-background-y" className="narrow-text-input" value={this.state.background.y} onChange={e => this.setState({ background: { ...this.state.background, y: Number(e.target.value) } })} />
      <label htmlFor="transcoding-livestreaming-background-width">width: </label>
      <input type="text" id="transcoding-livestreaming-background-width" className="narrow-text-input" value={this.state.background.width} onChange={e => this.setState({ background: { ...this.state.background, width: Number(e.target.value) } })} />
      <label htmlFor="transcoding-livestreaming-background-height">height: </label>
      <input type="text" id="transcoding-livestreaming-background-height" className="narrow-text-input" value={this.state.background.height} onChange={e => this.setState({ background: { ...this.state.background, height: Number(e.target.value) } })} />
      <br />
      <label>watermarks: </label>
      <label htmlFor={`transcoding-livestreaming-watermark-url-0`}>url: </label>
      <input type="text" id={`transcoding-livestreaming-watermark-url-0`} value={this.state.watermarks[0].url} onChange={e => this.setState({ watermarks: [{ ...this.state.watermarks[0], url: e.target.value }, ...this.state.watermarks.slice(1)] })} />
      <br />
      <label htmlFor={`transcoding-livestreaming-watermark-x-0`}>x: </label>
      <input type="text" id={`transcoding-livestreaming-watermark-x-0`} className="narrow-text-input" value={this.state.watermarks[0].x} onChange={e => this.setState({ watermarks: [{ ...this.state.watermarks[0], x: Number(e.target.value) }, ...this.state.watermarks.slice(1)] })} />
      <label htmlFor={`transcoding-livestreaming-watermark-y-0`}>y: </label>
      <input type="text" id={`transcoding-livestreaming-watermark-y-0`} className="narrow-text-input" value={this.state.watermarks[0].y} onChange={e => this.setState({ watermarks: [{ ...this.state.watermarks[0], y: Number(e.target.value) }, ...this.state.watermarks.slice(1)] })} />
      <label htmlFor={`transcoding-livestreaming-watermark-width-0`}>width: </label>
      <input type="text" id={`transcoding-livestreaming-watermark-width-0`} className="narrow-text-input" value={this.state.watermarks[0].width} onChange={e => this.setState({ watermarks: [{ ...this.state.watermarks[0], width: Number(e.target.value) }, ...this.state.watermarks.slice(1)] })} />
      <label htmlFor={`transcoding-livestreaming-watermark-height-0`}>height: </label>
      <input type="text" id={`transcoding-livestreaming-watermark-height-0`} className="narrow-text-input" value={this.state.watermarks[0].height} onChange={e => this.setState({ watermarks: [{ ...this.state.watermarks[0], height: Number(e.target.value) }, ...this.state.watermarks.slice(1)] })} />
      <br />
      <button onClick={this.startTranscodingLiveStreaming}>开始合流</button>
      <br />
      <label htmlFor="transcoding-livestreaming-update-streamID">streamID: </label>
      <input type="text" id="transcoding-livestreaming-update-streamID" value={this.state.updateStreamID} onChange={e => this.setState({ updateStreamID: e.target.value })} />
      <label htmlFor="transcoding-livestreaming-tracks">选择 tracks：</label>
      <select id={`transcoding-livestreaming-tracks`} value={this.state.selectedTrack} onChange={e => this.setState({ selectedTrack: e.target.value })}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.tracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}-{t.isAudio() ? "audio" : "video"}</option>
        ))}
      </select>
      <br />
      <label htmlFor={`transcoding-livestreaming-video-x`}>x: </label>
      <input type="text" id={`transcoding-livestreaming-video-x`} className="narrow-text-input" value={this.state.trackX} onChange={e => this.setState({ trackX: Number(e.target.value) })} />
      <label htmlFor={`transcoding-livestreaming-video-y`}>y: </label>
      <input type="text" id={`transcoding-livestreaming-video-y`} className="narrow-text-input" value={this.state.trackY} onChange={e => this.setState({ trackY: Number(e.target.value) })} />
      <label htmlFor={`transcoding-livestreaming-video-width`}>width: </label>
      <input type="text" id={`transcoding-livestreaming-video-width`} className="narrow-text-input" value={this.state.trackWidth} onChange={e => this.setState({ trackWidth: Number(e.target.value) })} />
      <label htmlFor={`transcoding-livestreaming-video-height`}>height: </label>
      <input type="text" id={`transcoding-livestreaming-video-heigth`} className="narrow-text-input" value={this.state.trackHeight} onChange={e => this.setState({ trackHeight: Number(e.target.value) })} />
      <label htmlFor={`transcoding-livestreaming-video-zOrder`}>zOrder: </label>
      <input type="text" id={`transcoding-livestreaming-video-zOrder`} className="narrow-text-input" value={this.state.trackZOrder} onChange={e => this.setState({ trackZOrder: Number(e.target.value) })} />
      <label htmlFor={`transcoding-livestreaming-video-render-mode`}>renderMode: </label>
      <select id={`transcoding-livestreaming-video-render-mode`} value={this.state.trackRenderMode} onChange={e => this.setState({ trackRenderMode: e.target.value as QNRenderMode })}>
        <option value={QNRenderMode.ASPECT_FILL}>{QNRenderMode.ASPECT_FILL}</option>
        <option value={QNRenderMode.ASPECT_FIT}>{QNRenderMode.ASPECT_FIT}</option>
        <option value={QNRenderMode.FILL}>{QNRenderMode.FILL}</option>
      </select>
      <br />
      <button id="transcoding-livestreaming-set-tracks-btn" onClick={this.handleSetTrack}>setTranscodingLiveStreamingTracks</button>
      <button id="transcoding-livestreaming-remove-tracks-btn" onClick={this.handleRemoveTrack}>removeTranscodingLiveStreamingTracks</button>
      <br />
      <label htmlFor="transcoding-livestreaming-stop-streamID">streamID: </label>
      <input type="text" id="transcoding-livestreaming-stop-streamID" value={this.state.stopStreamID} onChange={e => this.setState({ stopStreamID: e.target.value })} />
      <button onClick={this.stopTranscodingLiveStreaming}>停止合流</button>
      <div className="direct-livestreaming-state">
        <div>转推状态：</div>
        {
          Object.entries(this.state.livestreamingState)
            .map(([streamID, state]) => {
              return <div key={streamID}>{streamID}: {state}</div>;
            })
        }
      </div>
    </div >;
  }
}

