import * as React from "react";
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores/roomStore";
import TrackPlayer from '../TrackPlayer';
import { QNNetworkQuality } from "qnweb-rtc";
import "./style.css";

interface State {
  interval?: NodeJS.Timeout;
  networkQuality: QNNetworkQuality;
  userNetworkQuality: { [key: string]: QNNetworkQuality; };
  userReconnectState: { [key: string]: "reconnecting" | "reconnected"; };
}

interface Props {
  room: RoomStore;
  localTrackMirror: boolean;
  remoteTrackMirror: boolean;
}

@observer
export default class TracksPlayer extends React.Component<Props, State> {
  _isMounted = true;
  state: State = {
    networkQuality: QNNetworkQuality.UNKNOWN,
    userNetworkQuality: {},
    userReconnectState: {}
  };

  componentDidMount() {
    this._isMounted = true;
    const interval = setInterval(() => {
      if (this._isMounted) {
        this.setState({
          networkQuality: this.props.room.rtcClient.getNetworkQuality(),
          userNetworkQuality: this.props.room.remoteUsers.reduce(
            (pre, cur) => {
              return { ...pre, [cur.userID]: this.props.room.rtcClient.getUserNetworkQuality(cur.userID) };
            }, {}
          )
        });
      }
    }, 800);
    this.setState({ interval });
    this.props.room.rtcClient.on("user-reconnecting", this.handleUserReconnecting);
    this.props.room.rtcClient.on("user-reconnected", this.handleUserReconnected);
  }

  handleUserReconnecting = (userID: string) => {
    this.setState({
      userReconnectState: {
        ...this.state.userReconnectState, [userID]: "reconnecting"
      }
    });
  };

  handleUserReconnected = (userID: string) => {
    this.setState({
      userReconnectState: {
        ...this.state.userReconnectState, [userID]: "reconnected"
      }
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.interval) clearInterval(this.state.interval);
    this.props.room.rtcClient.off("user-reconnecting", this.handleUserReconnecting);
    this.props.room.rtcClient.off("user-reconnected", this.handleUserReconnected);
  }

  public render(): JSX.Element {
    return <div className="tracks-player">
      <h2>播放画面</h2>
      <div className="user-tracks-player-container">
        <h4>userID: {this.props.room.userID}, userData: {this.props.room.rtcClient.userData}, networkQuality: {this.state.networkQuality}</h4>
        <div className="user-tracks-player">
          {
            this.props.room.publishedTracks.map(track => <TrackPlayer key={track.trackID} track={track} isSelf={true} mirror={this.props.localTrackMirror}/>)
          }
        </div>
      </div>
      {
        this.props.room.remoteUsers.map(user => {
          return <div key={user.userID} className="user-tracks-player-container">
            <h4>
              UserID: {user.userID},
              userData: {user.userData},
              networkQuality: {this.state.userNetworkQuality[user.userID]},
              {this.state.userReconnectState[user.userID] ? this.state.userReconnectState[user.userID] : ""}
            </h4>
            <div className="user-tracks-player">
              {
                this.props.room.subscribedTracks
                  .filter(t => t.userID === user.userID)
                  .map(track => <TrackPlayer key={track.trackID} track={track} isSelf={false} mirror={this.props.remoteTrackMirror} />)
              }
            </div>
          </div>;
        })
      }
    </div>;
  }
}
