import * as React from 'react'
import { observer } from 'mobx-react'
import { RoomStore } from '../../stores/roomStore'
import QNRTC, {
  QNMediaRecorder,
  QNRecorderVideoSource,
  QNRecorderAudioSource,
  QNRecorderInfo,
  QNRecorderState,
} from 'qnweb-rtc'
import { NONE } from '../../constants'

interface Props {
  room: RoomStore
}

type RecordSource = (QNRecorderVideoSource | QNRecorderAudioSource) & {
  trackID: string
}

interface State {
  selectedTrack: string
  recorders: {
    recorder: QNMediaRecorder
    state: QNRecorderState
    info: Record<any, any>
    errCode: number
    errorMessage: string
  }[]
  trackX: number
  trackY: number
  trackWidth: number
  trackHeight: number
  trackZOrder: number
  option: RecordSource[]
  width: number
  height: number
  frameRate: number
  maxDurationMs: number
  recorderInfoUpdateInterval: number
}

const isVideoSource = (
  source: QNRecorderVideoSource | QNRecorderAudioSource
): source is QNRecorderVideoSource => {
  return source.track.kind === 'video'
}

@observer
export default class Recorder extends React.Component<Props, State> {
  state: State = {
    selectedTrack: NONE,
    recorders: [],
    trackX: 0,
    trackY: 0,
    trackWidth: 0,
    trackHeight: 0,
    trackZOrder: 0,
    option: [],
    height: 720,
    width: 1080,
    frameRate: 15,
    maxDurationMs: 120000,
    recorderInfoUpdateInterval: 1000
  }

  private handleStartRecorder(): void {
    const recorder = QNRTC.createMediaRecorder(this.state.option, {
      height: this.state.height,
      width: this.state.width,
      frameRate: this.state.frameRate,
      storagePath: `test${Date.now()}`,
      maxDurationMs: this.state.maxDurationMs,
      recorderInfoUpdateInterval: this.state.recorderInfoUpdateInterval,
    })
    recorder.addListener('state-changed', (state: QNRecorderState) => {
      const recorders = this.state.recorders
      const index = recorders.findIndex(item => item.recorder === recorder)
      recorders[index].state = state
      this.setState({
        recorders
      })
    })
    recorder.addListener('info-updated', (info: QNRecorderInfo) => {
      const recorders = this.state.recorders
      const index = recorders.findIndex(item => item.recorder === recorder)
      recorders[index].info = info
      this.setState({
        recorders
      })
    })
    recorder.addListener('error', (error: { code: any; message: any }) => {
      const recorders = this.state.recorders
      const index = this.state.recorders.findIndex(item => item.recorder === recorder)
      recorders[index].errCode = error.code
      recorders[index].errorMessage = error.message
      this.setState({
        recorders
      })
    })
    this.setState({
      recorders: [
        ...this.state.recorders,
        {
          recorder: recorder,
          state: QNRecorderState.IDLE,
          info: {},
          errCode: 0,
          errorMessage: '',
        },
      ],
    })
    recorder.startRecording()
  }

  /**
   * 停止录制
   */
  private handleStopRecorder(recorder: QNMediaRecorder): void {
    recorder.stopRecording()
  }

  /**
   * 释放录制资源
   */
  private handleReleaseRecorder(recorder: QNMediaRecorder): void {
    recorder.releaseRecoreding()
  }

  private handleAddTrack(): void {
    const track = this.props.room.tracks.find((item) => item.trackID === this.state.selectedTrack)
    if (track) {
      if (track.getMediaStreamTrack()) {
        this.setState({
          option: [
            ...this.state.option,
            {
              height: this.state.trackHeight,
              width: this.state.trackWidth,
              x: this.state.trackX,
              y: this.state.trackY,
              zOrder: this.state.trackZOrder,
              track: track.getMediaStreamTrack()!,
              trackID: this.state.selectedTrack,
            },
          ],
        })
      }
    }
  }

  render(): JSX.Element {
    return (
      <div className='audio-mixing-control-container '>
        <h2>录制接口</h2>
        <div className='create-audio-mixer-container'>
          <label htmlFor='transcoding-livestreaming-url'>width: </label>
          <input
            type='text'
            id='transcoding-livestreaming-url'
            value={this.state.width}
            onChange={(e) => this.setState({ width: Number(e.target.value) })}
          />
          <br />
          <label htmlFor='transcoding-livestreaming-url'>height: </label>
          <input
            type='text'
            id='transcoding-livestreaming-url'
            value={this.state.height}
            onChange={(e) => this.setState({ height: Number(e.target.value) })}
          />
          <br />
          <label htmlFor='transcoding-livestreaming-url'>frameRate: </label>
          <input
            type='text'
            id='transcoding-livestreaming-url'
            value={this.state.frameRate}
            onChange={(e) => this.setState({ frameRate: Number(e.target.value) })}
          />
          <br />
          <label htmlFor='transcoding-livestreaming-url'>maxDurationMs: </label>
          <input
            type='text'
            id='transcoding-livestreaming-url'
            value={this.state.maxDurationMs}
            onChange={(e) => this.setState({ maxDurationMs: Number(e.target.value) })}
          />
          <br />
          <label htmlFor='transcoding-livestreaming-url'>recorderInfoUpdateInterval: </label>
          <input
            type='text'
            id='transcoding-livestreaming-url'
            value={this.state.recorderInfoUpdateInterval}
            onChange={(e) => this.setState({ recorderInfoUpdateInterval: Number(e.target.value) })}
          />
          <br />
          <label htmlFor='audio-mixer-microphone-track'>选择要录制的 track:</label>
          <select
            id={`transcoding-livestreaming-tracks`}
            value={this.state.selectedTrack}
            onChange={(e) => this.setState({ selectedTrack: e.target.value })}
          >
            <option value={NONE}>{NONE}</option>
            {this.props.room.tracks.map((t) => (
              <option key={t.trackID} value={t.trackID}>
                {t.trackID}-{t.isAudio() ? 'audio' : 'video'}
              </option>
            ))}
          </select>
          <br />
          <label htmlFor={`transcoding-livestreaming-video-x`}>x: </label>
          <input
            type='text'
            id={`transcoding-livestreaming-video-x`}
            className='narrow-text-input'
            value={this.state.trackX}
            onChange={(e) => this.setState({ trackX: Number(e.target.value) })}
          />
          <label htmlFor={`transcoding-livestreaming-video-y`}>y: </label>
          <input
            type='text'
            id={`transcoding-livestreaming-video-y`}
            className='narrow-text-input'
            value={this.state.trackY}
            onChange={(e) => this.setState({ trackY: Number(e.target.value) })}
          />
          <label htmlFor={`transcoding-livestreaming-video-width`}>width: </label>
          <input
            type='text'
            id={`transcoding-livestreaming-video-width`}
            className='narrow-text-input'
            value={this.state.trackWidth}
            onChange={(e) => this.setState({ trackWidth: Number(e.target.value) })}
          />
          <label htmlFor={`transcoding-livestreaming-video-height`}>height: </label>
          <input
            type='text'
            id={`transcoding-livestreaming-video-heigth`}
            className='narrow-text-input'
            value={this.state.trackHeight}
            onChange={(e) => this.setState({ trackHeight: Number(e.target.value) })}
          />
          <label htmlFor={`transcoding-livestreaming-video-zOrder`}>zOrder: </label>
          <input
            type='text'
            id={`transcoding-livestreaming-video-zOrder`}
            className='narrow-text-input'
            value={this.state.trackZOrder}
            onChange={(e) => this.setState({ trackZOrder: Number(e.target.value) })}
          />
          <button onClick={this.handleAddTrack.bind(this)}>加入录制参数</button>
          <br />
          {this.state.option.map((item) =>
            isVideoSource(item) ? (
              <div key={item.trackID}>
                <span>track: {item.trackID}</span>
                <span>x: {item.x}</span>
                <span>y: {item.y}</span>
                <span>height: {item.height}</span>
                <span>width: {item.width}</span>
                <span>zOrder: {item.zOrder}</span>
              </div>
            ) : (
              <div key={item.trackID}>track: {item.trackID}</div>
            )
          )}
          <button id='start_recorder' onClick={this.handleStartRecorder.bind(this)}>
            开始录制
          </button>
          {this.state.recorders.map((item) => (
            <div>
              <button id='stop_recorder' onClick={this.handleStopRecorder.bind(this, item.recorder)}>
                停止录制
              </button>
              <button id='stop_recorder' onClick={this.handleReleaseRecorder.bind(this, item.recorder)}>
                销毁录制
              </button>
              <div>状态： {item.state}</div>
              {item.info && (
                <div>
                  info： 时间：{item.info.durationMs} 文件名：{item.info.fileName}{' '}
                  文件大小：{item.info.fileSize}
                </div>
              )}
              {item.errorMessage && (
                <div>
                  错误信息: code {item.errCode} message {item.errorMessage}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
