import { inject, observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import { PromptStore } from "../../../stores";
import "./index.css";
interface LoadingProps {
  prompt?: PromptStore;
}
@inject("prompt")
@observer
export default class Loading extends React.Component<LoadingProps, any> {
  render(): React.ReactNode {
    const {
      prompt: { loading: { show, content } } = {
        loading: { show: false, content: "" },
      },
    } = this.props;

    const loadingDom = (
      <div className="loading-wrapper">
        <div className="loading-mask" />
        <div className="loading-content">
          <span className="loading-icon">
            <svg viewBox="22 22 44 44">
              <circle cx={44} cy={44} r={20.2} fill="none" strokeWidth={3.6} />
            </svg>
          </span>
          <span className="loading-text">{content}</span>
        </div>
      </div>
    );

    return show ? ReactDOM.createPortal(loadingDom, document.body) : null;
  }
}
