import * as React from "react";
import Loading from "./components/Loading";

export class Root extends React.Component {
  public render(): JSX.Element {
    return (
      <div className="root">
        { this.props.children }
        <Loading></Loading>
      </div>
    );
  }
}