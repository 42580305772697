import * as React from 'react'
import { observer } from 'mobx-react'
import { RoomStore } from '../../stores/roomStore'
import QNRTC, {
  QNAudioEffectMixer,
  QNAudioSourceState,
  QNMicrophoneAudioTrack,
  QNAudioEffect,
  QNLocalAudioTrack,
} from 'qnweb-rtc'
import { NONE } from '../../constants'
import { isArray } from 'lodash'
import AudioMixingEffect from '../AudioMixingEffect/index'

interface Props {
  room: RoomStore
}

interface State {
  /**
   * 背景音乐文件
   */
  musicFile?: File
  /**
   * 背景音乐URL
   */
  musicURL: string
  /**
   * 选择的麦克风 trackID
   */
  selectedMicrophoneTrackID: string
  /**
   * effect 对象
   */
  audioEffect: { [key: string]: QNAudioEffect[] }
  /**
   * 外部音频处理状态
   */
  sourceState: QNAudioSourceState | ''
  /**
   * 麦克风音量
   */
  musicVolume: string
  /**
   * 扬声器音量
   */
  microphoneVolume: string
  /**
   * 所有音效音量
   */
  allEffectsVolume: string
  /**
   * 音效 ID
   */
  effectID: number
  /**
   * 混音音效控制器
   */
  audioEffectMixer?: QNAudioEffectMixer
  /**
   * 正在音乐过滤的音频轨
   */
  effectFilterTrackList: QNLocalAudioTrack[]
}

@observer
export default class AudioMixingControl extends React.Component<Props, State> {
  state: State = {
    musicURL: 'https://docs.qnsdk.com/eva_edm.mp3',
    selectedMicrophoneTrackID: NONE,
    sourceState: '',
    musicVolume: '1',
    microphoneVolume: '1',
    effectID: 0,
    audioEffect: {},
    allEffectsVolume: '1',
    audioEffectMixer: undefined,
    effectFilterTrackList: [],
  }

  private handleChangeMusicFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return
    this.state.musicFile = file
  }

  componentWillUnmount() {
    this.state.audioEffectMixer &&
      this.state.audioEffectMixer.off('finished', this.showEffectMixerFinish)
    this.state.audioEffectMixer &&
      this.state.audioEffectMixer.off('error', this.showEffectMixerError)
    this.state.audioEffectMixer &&
      this.state.audioEffectMixer.release()
  }

  private showEffectMixerError(error: any) {
    alert(`错误信息：${error.code} ${error.message}`)
  }

  private showEffectMixerFinish(effectID: number) {
    alert(`effectID：${effectID} is stopped`)
  }

  /**
   * 创建音效控制器
   * @returns
   */
  private createEffectMixer() {
    this.setState({
      audioEffectMixer: QNRTC.createAudioEffectMixer(),
    })
    if (this.state.audioEffectMixer) {
      this.state.audioEffectMixer.on('finished', this.showEffectMixerFinish)
      this.state.audioEffectMixer.on('error', this.showEffectMixerError)
    }
  }

  /**
   * 移除音效控制器
   */
  private destroyEffectMixer() {
    QNRTC.destroyAudioEffectMixer()
    this.setState({
      audioEffectMixer: undefined,
    })
  }

  /**
   * 添加音乐混音过滤器
   */
  addEffectAudioFilter() {
    const audioTrack = this.chooseSelectAudioTrack()
    if (audioTrack) {
      const chooseTrack = this.state.effectFilterTrackList.find((track) => track === audioTrack)
      if (!chooseTrack) {
        this.setState({
          effectFilterTrackList: [...this.state.effectFilterTrackList, audioTrack],
        })
        this.state.audioEffectMixer && audioTrack.addAudioFilter(this.state.audioEffectMixer)
      }
    }
  }

  /**
   * 移除音乐混音过滤器
   */
  removeEffectAudioFilter() {
    const audioTrack = this.chooseSelectAudioTrack()
    if (audioTrack) {
      const chooseTrackIndex = this.state.effectFilterTrackList.findIndex(
        (track) => track === audioTrack
      )
      if (chooseTrackIndex >= 0) {
        const effectFilterTrackList = [...this.state.effectFilterTrackList]
        effectFilterTrackList.splice(chooseTrackIndex, 1)
        this.setState({
          effectFilterTrackList,
        })
        this.state.audioEffectMixer && audioTrack.removeAudioFilter(this.state.audioEffectMixer)
      }
    }
  }

  /**
   * 选择选中的音频轨
   * @returns 本地音频轨
   */
  private chooseSelectAudioTrack(): QNLocalAudioTrack | undefined {
    const microphoneTrack = this.props.room.publishedTracks.filter(
      (t) => t.trackID === this.state.selectedMicrophoneTrackID
    )[0]
    if (!microphoneTrack) {
      console.error(
        'no selected microphone track, selectedMicrophoneTrackID: ',
        this.state.selectedMicrophoneTrackID
      )
      return
    }
    return microphoneTrack as QNLocalAudioTrack
  }

  /**
   * 创建音效
   */
  async handleCreateEffect(trackID: string) {
    if (this.state.audioEffectMixer) {
      let audioEffect: QNAudioEffect
      if (this.state.musicFile) {
        audioEffect = await this.state.audioEffectMixer.createAudioEffect(
          this.state.effectID,
          this.state.musicFile
        )
      } else {
        audioEffect = await this.state.audioEffectMixer.createAudioEffect(
          this.state.effectID,
          this.state.musicURL
        )
      }
      const audioEffectList = this.state.audioEffect[trackID]
      let newMixerMap = audioEffectList ? audioEffectList : []
      if (isArray(audioEffectList) && audioEffectList.length) {
        newMixerMap.push(audioEffect)
      } else {
        newMixerMap = [audioEffect]
      }
      this.setState({
        audioEffect: {
          ...this.state.audioEffect,
          [trackID]: newMixerMap,
        },
      })
    }
  }

  /**
   * 开启耳返
   * @param microphoneTrackID
   */
  setEarMonitorEnabled(microphoneTrackID: string) {
    const microphoneAudioTrack = this.handleGetMicrophoneAudioTrackById(microphoneTrackID)
    if (microphoneAudioTrack.isEarMonitorEnabled()) {
      microphoneAudioTrack.setEarMonitorEnabled(false)
    } else {
      microphoneAudioTrack.setEarMonitorEnabled(true)
    }
  }

  /**
   * 设置麦克风音量
   * @param microphoneTrackID
   */
  setMixingMicrophoneVolume(microphoneTrackID: string) {
    const microphoneAudioTrack = this.handleGetMicrophoneAudioTrackById(microphoneTrackID)
    microphoneAudioTrack.setVolume(Number(this.state.microphoneVolume))
  }

  /**
   * 根据 ID 获取音频 Track
   * @param microphoneTrackID ID
   * @returns 音频 Track
   */
  private handleGetMicrophoneAudioTrackById(microphoneTrackID: string): QNMicrophoneAudioTrack {
    const microphoneTrack = this.props.room.publishedTracks.filter(
      (t) => t.trackID === microphoneTrackID
    )[0]
    if (!microphoneTrack) {
      throw new Error(
        `no selected microphone track, selectedMicrophoneTrackID: ${microphoneTrackID}`
      )
    } else {
      return microphoneTrack as QNMicrophoneAudioTrack
    }
  }

  public render(): JSX.Element {
    return (
      <div className='audio-mixing-control-container '>
        <h2>混音音效控制-新版</h2>
        <div className='create-audio-mixer-container'>
          { !this.state.audioEffectMixer && <button id='api-createAudioMixer' onClick={() => this.createEffectMixer()}>
            创建音效处理
          </button> }
          { this.state.audioEffectMixer && <button id='api-createAudioMixer' onClick={() => this.destroyEffectMixer()}>
            移除音效处理
          </button> }
          <br />
          <label htmlFor='audio-mixer-microphone-track'>选择麦克风 Track: </label>
          <select
            id='audio-mixer-microphone-track'
            value={this.state.selectedMicrophoneTrackID}
            onChange={(e) => this.setState({ selectedMicrophoneTrackID: e.target.value })}
          >
            <option value={NONE}>{NONE}</option>
            {this.props.room.localAudioTracks.map((t) => (
              <option key={t.trackID} value={t.trackID}>
                {t.trackID}
              </option>
            ))}
          </select>
          <br />
          <button
            id="api-createAudioMixer"
            onClick={() => this.addEffectAudioFilter()}
          >
            添加混音过滤器
          </button>
          <br />
          <button
            id="api-createAudioMixer"
            onClick={() => this.removeEffectAudioFilter()}
          >
            移除混音过滤器
          </button>
          <br />
        </div>
        <div>
          {this.state.effectFilterTrackList.map((track) => {
            return (
              <div key={track.trackID}>
                <h4>: audioTrack:{track.trackID}</h4>
                <label htmlFor='audio-mixer-source-url'>输入音效id：</label>
                <input
                  className='online-audio'
                  type='number'
                  value={this.state.effectID}
                  onChange={(e) => this.setState({ effectID: ~~e.target.value })}
                />
                <br />
                <label htmlFor='audio-mixer-source-input'>选择音乐文件： </label>
                <input
                  id='audio-mixer-source-input'
                  type='file'
                  accept='.mp3, .ogg'
                  onChange={this.handleChangeMusicFile}
                />
                <br />
                <label htmlFor='audio-mixer-source-url'>输入音乐地址：</label>
                <input
                  className='online-audio'
                  type='text'
                  value={this.state.musicURL}
                  onChange={(e) => this.setState({ musicURL: e.target.value })}
                />
                <br />
                <button
                  className='audio-mixer-control-playback'
                  onClick={() => this.setEarMonitorEnabled(track.trackID!)}
                >
                  开启/关闭耳返
                </button>
                <br />
                <label htmlFor='audio-mixer-playing-volume-music'>麦克风音量</label>
                <input
                  type='text'
                  id='audio-mixer-playing-volume-music'
                  value={this.state.microphoneVolume}
                  onChange={(e) =>
                    this.setState({
                      microphoneVolume: e.target.value,
                    })
                  }
                />
                <button onClick={() => this.setMixingMicrophoneVolume(track.trackID!)}>
                  设置混音扬声器音量
                </button>
                <br />
                <button
                  id='api-createAudioMixer'
                  onClick={() => this.handleCreateEffect(track.trackID!)}
                >
                  创建音效
                </button>
                <button
                  className='audio-mixer-control-playback'
                  onClick={() =>
                    this.state.audioEffectMixer && this.state.audioEffectMixer.stopAll()
                  }
                >
                  停止所有音效
                </button>
                <button
                  className='audio-mixer-control-playback'
                  onClick={() =>
                    this.state.audioEffectMixer && this.state.audioEffectMixer.pauseAll()
                  }
                >
                  暂停所有音效
                </button>
                <button
                  className='audio-mixer-control-playback'
                  onClick={() =>
                    this.state.audioEffectMixer && this.state.audioEffectMixer.resumeAll()
                  }
                >
                  恢复所有音效
                </button>
                <br />
                <label htmlFor='audio-mixer-playing-volume-input'>设置所有音效音量:</label>
                <input
                  type='text'
                  id='audio-mixer-playing-volume-input'
                  value={this.state.allEffectsVolume}
                  onChange={(e) => this.setState({ allEffectsVolume: e.target.value })}
                />
                <button
                  onClick={() =>
                    this.state.audioEffectMixer &&
                    this.state.audioEffectMixer.setAllEffectsVolume(
                      Number(this.state.allEffectsVolume)
                    )
                  }
                >
                  设置所有音效音量
                </button>

                {this.state.audioEffect[track.trackID!] &&
                  this.state.audioEffect[track.trackID!].map((effect, index) => {
                    return (
                      <AudioMixingEffect
                        key={index}
                        room={this.props.room}
                        microphoneTrackID={track.trackID!}
                        mixer={this.state.audioEffectMixer!}
                        effect={effect}
                      />
                    )
                  })}
              </div>
            )
          })}
        </div>
        <br />
      </div>
    )
  }
}
