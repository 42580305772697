import { configure } from 'mobx';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { RouterStore, PromptStore, RoomStore } from './stores';

// use async action
configure({
  enforceActions: "never",
});

let basename = "/";
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_BASENAME) {
  basename = process.env.REACT_APP_BASENAME;
}
const history = createBrowserHistory({ basename });
const routerStore = new RouterStore(history);
const promptStore = new PromptStore();
const roomStore = new RoomStore(routerStore);
const rootStore = {
  router: routerStore,
  prompt: promptStore,
  room: roomStore,
};

ReactDOM.render(
  <Provider {...rootStore}>
    <App history={history}/>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);