import * as React from "react";
import { observer } from "mobx-react";
import { RoomStore } from "../../stores/roomStore";
import { QNAudioEffectMixer, QNAudioSourceState,QNAudioEffect } from "qnweb-rtc";

interface Props {
  mixer: QNAudioEffectMixer;
  effect: QNAudioEffect;
  microphoneTrackID: string;
  room: RoomStore;
}

interface State {
  /**
   * 外部音频处理状态
   */
  sourceState: QNAudioSourceState | "";
  /**
   * 麦克风音量
   */
  musicVolume: string;
  /**
   * 扬声器音量
   */
  microphoneVolume: string;
  /**
   * frameRequestNumber
   */
  frameRequestNumber: number;
  /**
   * 当前音量
   */

  volume: number;
  /**
   * 当前位置
   */
  currentPosition: number;
  /**
   * 循环次数
   */
  loopCount: string;
  /**
   * 当前剩余循环次数
   */
  currentLoopCount: number
}

@observer
export default class AudioMixingControl extends React.Component<Props, State> {
  state: State = {
    sourceState: "",
    musicVolume: "1",
    microphoneVolume: "1",
    frameRequestNumber: 0,
    volume: 0,
    currentPosition: 0,
    loopCount: "1",
    currentLoopCount: 1
  };

  componentDidMount() {
    const callback = () => {
      this.setState({
        volume: this.props.mixer.getVolume(this.props.effect.getID()),
        currentPosition: this.props.mixer.getCurrentPosition(
          this.props.effect.getID()
        ),
        currentLoopCount: this.props.effect.getLoopCount()
      });
      let frameRequestNumber = requestAnimationFrame(callback);
      this.setState({
        frameRequestNumber,
      });
    };
    requestAnimationFrame(callback);
  }
  componentWillUnmount() {
    cancelAnimationFrame(this.state.frameRequestNumber);
  }

  setVolume() {
    this.props.mixer.setVolume(this.props.effect.getID(), Number(this.state.musicVolume));
  }

  setLoopCount() {
    this.props.effect.setLoopCount(Number(this.state.loopCount));
  }

  public render(): JSX.Element {
    return (
      <div className="audio-mixer-item">
        <div>
          <label htmlFor="audio-mixer-playing-volume-microphone">
            音效播放音量:
          </label>
          <input
            type="text"
            id="audio-mixer-playing-volume-microphone"
            value={this.state.musicVolume}
            onChange={(e) => this.setState({ musicVolume: e.target.value })}
          />
          <button onClick={() => this.setVolume()}>设置音效播放音量</button>
          <br />
          <label htmlFor="audio-mixer-playing-volume-microphone">
            循环次数:
          </label>
          <input
            type="text"
            id="audio-mixer-playing-volume-microphone"
            value={this.state.loopCount}
            onChange={(e) => this.setState({ loopCount: e.target.value })}
          />
          <button onClick={() => this.setLoopCount()}>设置循环次数</button>
          <br />
          <button
            className="audio-mixer-control-start"
            onClick={() => this.props.mixer.start(this.props.effect.getID())}
          >
            start
          </button>
          <button
            className="audio-mixer-control-stop"
            onClick={() => this.props.mixer.stop(this.props.effect.getID())}
          >
            stop
          </button>
          <button
            className="audio-mixer-control-pause"
            onClick={() => this.props.mixer.pause(this.props.effect.getID())}
          >
            pause
          </button>
          <button
            className="audio-mixer-control-resume"
            onClick={() => this.props.mixer.resume(this.props.effect.getID())}
          >
            resume
          </button>
          <br />
          <span>当前混音位置：{this.state.currentPosition}</span>
          <br />
          <span>当前音量：{this.state.volume}</span>
          <br />
          <span>剩余循环次数：{this.state.currentLoopCount}</span>
          <br />
          <span>effectID：{this.props.effect.getID()}</span>
          <br />
          <span>duration：{this.props.effect.getDuration()}</span>
          <br />
          <span>filePath：{this.props.effect.getFilePath()}</span>
        </div>
      </div>
    );
  }
}
