import { QNRemoteTrack, QNRTCClient } from "qnweb-rtc";

export function assertUserPublished(userID: string, tracks: QNRemoteTrack[], client: QNRTCClient) {
  const tag = "user-published";

  for (const t of tracks) {
    console.assert(t.userID === userID, tag, `assert userID fail, expect ${userID}, got ${t.userID}`);
  }

  const remoteUser = client.remoteUsers.find(user => user.userID === userID);
  console.assert(remoteUser !== undefined, tag, `assert remoteUser fail, cannot find remoteUser with userID: ${userID}`);

  const remoteTracks: QNRemoteTrack[] = [...remoteUser!.getAudioTracks(), ...remoteUser!.getVideoTracks()]
  for (const t of tracks) {
    console.assert(remoteTracks.includes(t), tag, `assert remoteTrack fail, cannot find track in user with trackID: ${t.trackID}`)
  }
}
