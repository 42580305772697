import { observable, action } from 'mobx';

interface AlertPrompt {
  show?: boolean;
  title?: string;
  content: string;
  enter?: string;
  cancel?: string;
  onEnter?: () => void;
  onCancel?: () => void;
}

interface ToastPrompt {
  show?: boolean;
  content: string;
  action?: string;
  onAction?: string;
}

interface Loading {
  show?: boolean;
  content: string;
}

export class PromptStore {
  @observable
  public alertPrompt: AlertPrompt;

  @observable
  public toastPrompt: ToastPrompt;

  @observable
  public loading: Loading;

  public constructor() {
    this.alertPrompt = {
      show: false,
      title: '错误！',
      enter: '确定',
      content: '',
    };

    this.toastPrompt = {
      show: false,
      content: '',
    };

    this.loading = {
      show: false,
      content: '',
    };
  }

  @action
  public closeAlert = () => {
    this.alertPrompt.show = false;
  }

  @action
  public closeToast = () => {
    this.toastPrompt.show = false;
  }

  @action
  public closeLoading = () => {
    this.loading.show = false;
  }

  @action
  public showAlert = (alertPrompt: AlertPrompt) => {
    alertPrompt.show = true;
    this.alertPrompt = alertPrompt;
  }

  @action
  public showToast = (toastPrompt: ToastPrompt) => {
    toastPrompt.show = true;
    this.toastPrompt = toastPrompt;
  }

  @action
  public showLoading = (loading: Loading) => {
    loading.show = true;
    this.loading = loading;
  }
}
