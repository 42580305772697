import * as React from "react";

interface Props {
  localTrackMirror: boolean;
  remoteTrackMirror: boolean;
  setMirror: (direction: "local" | "remote", mirror: boolean) => void
}

export default function MirrorControl(props: Props) {
  return <div id="mirror-control-container">
    <h2>Play mirror control</h2>
    <label htmlFor="config-local-mirror">Local Track Mirror: </label>
    <input type="checkbox" id="config-local-mirror" checked={props.localTrackMirror} onChange={e => props.setMirror("local", e.target.checked)} />
    <br />
    <label htmlFor="config-remote-mirror">Remote Track Mirror: </label>
    <input type="checkbox" id="config-remote-mirror" checked={props.remoteTrackMirror} onChange={e => props.setMirror("remote", e.target.checked)} />
  </div>;
}
