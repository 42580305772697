
import * as React from "react";
import { observer } from 'mobx-react';
import { formatSecToTimeString } from "../../utils";

/// <reference path="materialuislider.d.ts"/>
import { Slider } from 'material-ui-slider';
import { QNBufferSourceAudioTrack, QNAudioMusicMixer } from "qnweb-rtc";

interface State {
  interval?: NodeJS.Timeout;
  currentTime: number;
  duration: number;
}

interface Props {
  controller?: QNBufferSourceAudioTrack | QNAudioMusicMixer;
}

@observer
export default class MusicBar extends React.Component<Props, State> {
  public state: State = {
    currentTime: 0,
    duration: 0,
  };

  componentDidMount() {
    const interval = setInterval(() => {
      if (!this.props.controller) return;
      const currentTime = this.props.controller.getCurrentPosition();
      const duration = this.props.controller.getDuration();
      this.setState({ currentTime, duration });
    }, 800);
    this.setState({ interval });
  }

  componentWillUnmount() {
    if (this.state.interval) clearInterval(this.state.interval);
  }

  handleSeekTo(value: number) {
    if (this.props.controller) {
      this.props.controller.seekTo(this.state.duration * value / 100);
    }
  }

  public render(): JSX.Element {
    return <div className="music-bar">
      <label>{formatSecToTimeString(this.state.currentTime)}</label>
      <Slider
        value={this.state.duration === 0 ? 0 : this.state.currentTime * 100 / this.state.duration}
        onChange={this.handleSeekTo.bind(this)}
      ></Slider> <br />
      <label>{formatSecToTimeString(this.state.duration)}</label>
    </div>;
  }
}
